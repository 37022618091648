import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SvgAttribute } from "@/shared/constants/constants";
import { ApplicationStatus } from "@/shared/constants/enums";
import { ApplicationHistoryResponse } from "@/shared/repository/modules/application/repo";
import { formatFiatCurrency, formatCryptoCurrency } from "@/shared/utils/amount-helpers";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    row: {
      type: <PropType<ApplicationHistoryResponse>>Object,
      required: true,
    },
  },
  emits: {
    application: null,
    remove: null,
  },
  computed: {
    displayedSvg(): SvgAttribute {
      return SVG;
    },

    displayedStatus(): string {
      switch (this.row.status) {
        case ApplicationStatus.pending:
          return "Создано";
        case ApplicationStatus.waiting:
          return "Ожидание подтверждения";
        case ApplicationStatus.inWork:
          return "В работе";
        case ApplicationStatus.reject:
          return "Отклонено";
        case ApplicationStatus.completed:
          return "Выполнено";
        default:
          return "Создано";
      }
    },

    displayedAmountFrom(): string {
      return formatFiatCurrency(this.row.amount);
    },

    displayedAmountTo(): string {
      if (this.row.currency_type_to === AdminCurrencyType.fiat) {
        return formatFiatCurrency((this.row.amount - this.row.commissions) * this.row.execution_price);
      } else {
        return formatCryptoCurrency((this.row.amount - this.row.commissions) * this.row.execution_price);
      }
    },

    displayedExecutionPrice(): string {
      if (this.row.currency_type_to === AdminCurrencyType.fiat) {
        return formatFiatCurrency(this.row.execution_price);
      } else {
        return formatCryptoCurrency(this.row.execution_price);
      }
    },

    displayedTransactionVolume(): string {
      if (this.row.currency_type_to === AdminCurrencyType.fiat) {
        return formatFiatCurrency(this.row.transaction_volume);
      } else {
        return formatCryptoCurrency(this.row.transaction_volume);
      }
    },

    displayedCommission(): string {
      if (this.row.currency_type_from === AdminCurrencyType.fiat) {
        return formatFiatCurrency(this.row.commissions);
      } else {
        return formatCryptoCurrency(this.row.commissions);
      }
    },

    isRemoveShow(): boolean {
      return this.row.status === ApplicationStatus.pending;
    },
  },
  methods: {
    handleRemove(): void {
      this.$emit("remove", this.row.id);
    },

    handleRow(): void {
      this.$emit("application", this.row);
    },

    displayLastStatus(status: string): string {
      const lines = status.split("\n")
      if (lines.length > 1) {
        const lastLine = lines.pop()
        while(lastLine) {
          return lastLine || status
        }
      }
      return status
    }
  },
});
