<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <div :class="b('field')">
        <p :class="b('field-label')">
          {{ $t("Выберите шаблон реквизитов") }}
        </p>

        <SharedSelect
          view="light"
          :options="displayedRequisiteOptions"
          v-model:value="depositWithdrawalStore.user_requisite_id"
        />
      </div>

      <div :class="b('fields-group')">
        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Валюта") }}
          </p>

          <SharedSelect
            view="light"
            :options="displayedCurrencyOptions"
            :icon="displayedCurrencyIcon"
            v-model:value="depositWithdrawalStore.currency_id"
          />
        </div>

        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Сумма") }}
          </p>

          <UIInput
            view="light"
            type="number"
            :is-number="true"
            v-model="depositWithdrawalStore.amount"
          />

          <p
            :class="b('message-error')"
            v-if="
              isWithdrawal &&
              depositWithdrawalStore.currency_id &&
              (displayedCurrentWalletWithAmount?.amount ?? 0) <
                depositWithdrawalStore.amount
            "
          >
            {{ $t('Не хватает средств') }}
          </p>
        </div>
      </div>

      <div v-if="isCommissionsShow" :class="b('message', { amount: true })">
        <div :class="b('message-item')">
          <p :class="b('message-label')">
            {{ $t("Комиссия") }}
          </p>

          <p :class="b('message-label')">
            {{ displayedCommission }}
            {{
              displayedCurrencyName
            }}
          </p>
        </div>

        <div :class="b('message-item')">
          <p :class="b('message-label')">
            {{ $t("Вы получите") }}
          </p>

          <p :class="b('message-label')">
            ~
            {{ displayedAmount }}
            {{
              displayedCurrencyName
            }}
          </p>
        </div>
      </div>
    </div>

    <UIButton
      label="Создать заявку"
      view="main"
      :disabled="isDisabled"
      @click="handleCreate"
    />
  </div>
</template>
