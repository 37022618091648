import { AxiosInstance } from "axios";

import { ResponseData } from "@/shared/constants/interfaces";

import { getBearerToken } from "@/shared/utils/token-helpers";

import { UserUrlGenerator } from "./url-generator";
import {
  UserFileRequest,
  UserFileResponse,
  UserInfoResponse,
  UserLanguageRequest,
  UserAgreementRequest,
  UserResponse,
} from "./repo";
import {
  UserFileResponseFactory,
  UserInfoResponseFactory,
  UserResponseFactory,
} from "./factory";

import { FileFor } from "@/shared/constants/enums";

export interface UserProjectRepository {
  user(): Promise<UserResponse>;
  userInfo(): Promise<UserInfoResponse>;
  language(payload: UserLanguageRequest): Promise<void>;
  userAgreement(payload: UserAgreementRequest): Promise<void>;

  upload(payload: UserFileRequest): Promise<UserFileResponse>;
  delete(id: number): Promise<void>;
  files(
    fileFor: FileFor,
    userId: number,
    participantId?: number
  ): Promise<UserFileResponse[]>;
  filesById(
    userId: number,
    fileFor: FileFor,
    participantId?: number
  ): Promise<UserFileResponse[]>;
}

export class UserHttpRepo implements UserProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: UserUrlGenerator
  ) {}

  async user(): Promise<UserResponse> {
    const { data } = await this.axios.get<UserResponse>(
      this.urlGenerator.user()
    );

    return UserResponseFactory(data);
  }

  async userInfo(): Promise<UserInfoResponse> {
    const { data } = await this.axios.get<ResponseData<UserInfoResponse>>(
      this.urlGenerator.userInfo(),
      {
        headers: {
          "Authorization": getBearerToken()
        }
      }
    );

    return UserInfoResponseFactory(data.data);
  }

  async language(payload: UserLanguageRequest): Promise<void> {
    await this.axios.patch<void>(this.urlGenerator.language(), payload);
  }

  async upload(payload: UserFileRequest): Promise<UserFileResponse> {
    const { data } = await this.axios.post<ResponseData<UserFileResponse>>(
      this.urlGenerator.upload(
        payload.fileFor,
        payload.type,
        payload.kyb_participant_id
      ),
      {
        file: payload.file,
        name: payload.name,
      }
    );

    return UserFileResponseFactory(data.data);
  }

  async delete(id: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.delete(id));
  }

  async userAgreement(payload: UserAgreementRequest): Promise<void> {
    await this.axios.patch<void>(this.urlGenerator.userAgreement(), payload);
  }

  async files(
    fileFor: FileFor,
    userId: number,
    participantId?: number
  ): Promise<UserFileResponse[]> {
    const { data } = await this.axios.get<ResponseData<UserFileResponse[]>>(
      this.urlGenerator.files(userId, fileFor, participantId)
    );

    return data.data.map((file) => UserFileResponseFactory(file));
  }

  async filesById(
    userId: number,
    fileFor: FileFor,
    participantId?: number
  ): Promise<UserFileResponse[]> {
    const { data } = await this.axios.get<ResponseData<UserFileResponse[]>>(
      this.urlGenerator.files(userId, fileFor, participantId)
    );

    return data.data.map((file) => UserFileResponseFactory(file));
  }
}
