import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedWindow } from "@/components/shared";
import { UIButton } from "@/components/ui";
import { DepositWithdrawalHistoryResponseFactory } from "@/shared/repository/modules/deposit-withdrawal/factory";
import { formatFiatCurrency } from "@/shared/utils/amount-helpers";
import { ApplicationStatus } from "@/shared/constants/enums";
import { AdminCommissionRequisitesListResponse } from "@/shared/repository/modules/admin/commission/repo";
import { DepositWithdrawalHistoryResponse } from "@/shared/repository/modules/deposit-withdrawal/repo";

interface HistoryDetails {
  dateTime: HistoryDetailsParams;
  type: HistoryDetailsParams;
  amountCurrency: HistoryDetailsParams;
  exchange: HistoryDetailsParams;
  status: HistoryDetailsParams;
  address: {
    title: string;
    label: string;
    info?: {
      rs?: HistoryDetailsParams;
      ks?: HistoryDetailsParams;
      bik?: HistoryDetailsParams;
      name?: HistoryDetailsParams;
      inn?: HistoryDetailsParams;
      kpp?: HistoryDetailsParams;
    };
  } | null;
}

interface HistoryDetailsParams {
  title: string;
  label: string;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    UIButton,
  },
  props: {
    depositWithdrawal: {
      type: <PropType<DepositWithdrawalHistoryResponse>>Object,
      required: true,
    },
  },
  emits: {
    depositWithdrawal: null,
  },
  data() {
    return {
      isLoading: false,
      details: DepositWithdrawalHistoryResponseFactory(),
      requisites: <AdminCommissionRequisitesListResponse | null>null,
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
      depositWithdrawalRepository:
        this.$projectServices.depositWithdrawalRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      this.requisites = await this.depositWithdrawalRepository.requisite(
        this.depositWithdrawal.currency_id
      );
    } catch (error) {
      console.log("ERROR", error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedList(): HistoryDetails {
      const { created_at, app_type, amount, currency, commissions, status } =
        this.depositWithdrawal;
      const bank = this.requisites?.mex_requisite_banks.find(
        (bank) => bank.id === this.depositWithdrawal.mex_requisite_id
      );

      const getStatus = (): string => {
        switch (status) {
          case ApplicationStatus.pending:
            return "Создано";
          case ApplicationStatus.waiting:
            return "Ожидание подтверждения";
          case ApplicationStatus.inWork:
            return "В работе";
          case ApplicationStatus.reject:
            return "Отклонено";
          case ApplicationStatus.completed:
            return "Выполнено";
          default:
            return "Создано";
        }
      };

      return {
        dateTime: {
          title: "Дата и время",
          label: created_at,
        },
        type: {
          title: "Тип",
          label: app_type === "deposit" ? "Пополнение" : "Вывод",
        },
        amountCurrency: {
          title: "Сумма и валюта",
          label: `${formatFiatCurrency(amount)} ${currency.name.toUpperCase()}`,
        },
        exchange: {
          title: "Комиссия",
          label: `${commissions} ${currency.name.toUpperCase()}`,
        },
        status: {
          title: "Статус",
          label: getStatus(),
        },
        address:
          this.requisites !== null && bank
            ? {
                title: "Адрес перевода",
                label: "",
                info: {
                  ks: {
                    title: "К/С",
                    label: bank.correspondent_account,
                  },
                  bik: {
                    title: "БИК",
                    label: bank.bic,
                  },
                  name: {
                    title: "Полное наименование банка",
                    label: this.requisites.org_full_name_local,
                  },
                  inn: {
                    title: "ИНН",
                    label: bank.inn,
                  },
                },
              }
            : null,
      };
    },

    isDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("depositWithdrawal", null);
    },
  },
});
