import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedAdminHeader = _resolveComponent("SharedAdminHeader")!
  const _component_AdminApplicationHeader = _resolveComponent("AdminApplicationHeader")!
  const _component_AdminApplicationTable = _resolveComponent("AdminApplicationTable")!
  const _component_SharedButtonIcon = _resolveComponent("SharedButtonIcon")!
  const _component_AdminApplicationEdit = _resolveComponent("AdminApplicationEdit")!
  const _component_AdminApplicationEditApplication = _resolveComponent("AdminApplicationEditApplication")!
  const _component_HistoryDetails = _resolveComponent("HistoryDetails")!
  const _component_HistoryApplicationDetails = _resolveComponent("HistoryApplicationDetails")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_SharedAdminHeader, {
      title: "Заявки",
      "is-type": false,
      "is-loading": _ctx.isLoading,
      "is-email": true,
      id: _ctx.search.id,
      "onUpdate:id": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search.id) = $event)),
      email: _ctx.search.email,
      "onUpdate:email": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search.email) = $event)),
      onSearch: _ctx.handleSearch
    }, null, 8, ["is-loading", "id", "email", "onSearch"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      _createVNode(_component_AdminApplicationHeader, {
        tab: _ctx.tab,
        "onUpdate:tab": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tab) = $event)),
        search: _ctx.search.value,
        "onUpdate:search": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.search.value) = $event)),
        history: _ctx.history,
        "onUpdate:history": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.history) = $event)),
        page: _ctx.sort.page,
        "onUpdate:page": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.sort.page) = $event))
      }, null, 8, ["tab", "search", "history", "page"]),
      _createVNode(_component_AdminApplicationTable, {
        class: _normalizeClass(_ctx.b('table')),
        search: _ctx.search,
        sort: _ctx.sort,
        tab: _ctx.tab,
        history: _ctx.history,
        "onUpdate:history": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.history) = $event)),
        "deposit-withdrawal": _ctx.depositWithdrawal,
        "onUpdate:depositWithdrawal": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.depositWithdrawal) = $event)),
        application: _ctx.application,
        "onUpdate:application": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.application) = $event)),
        "is-work": _ctx.isWork,
        "onUpdate:isWork": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isWork) = $event)),
        "is-reject": _ctx.isReject,
        "onUpdate:isReject": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isReject) = $event)),
        onDetailHistory: _ctx.handleDetailHistory,
        onApplicationHistory: _ctx.handleApplicationHistory
      }, null, 8, ["class", "search", "sort", "tab", "history", "deposit-withdrawal", "application", "is-work", "is-reject", "onDetailHistory", "onApplicationHistory"]),
      (_ctx.isMoreShow)
        ? (_openBlock(), _createBlock(_component_SharedButtonIcon, {
            key: 0,
            class: _normalizeClass(_ctx.b('more')),
            view: "gray-outline",
            onClick: _ctx.handleMore
          }, {
            "icon-left": _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('more-icon')),
                innerHTML: _ctx.displayedIcons.more
              }, null, 10, _hoisted_1)
            ]),
            label: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("Показать больше")), 1)
            ]),
            _: 1
          }, 8, ["class", "onClick"]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.depositWithdrawal.id)
          ? (_openBlock(), _createBlock(_component_AdminApplicationEdit, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              history: _ctx.history,
              "onUpdate:history": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.history) = $event)),
              "deposit-withdrawal": _ctx.depositWithdrawal,
              "onUpdate:depositWithdrawal": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.depositWithdrawal) = $event)),
              "is-work": _ctx.isWork,
              "onUpdate:isWork": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.isWork) = $event)),
              "is-reject": _ctx.isReject,
              "onUpdate:isReject": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.isReject) = $event))
            }, null, 8, ["class", "history", "deposit-withdrawal", "is-work", "is-reject"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.application.id)
          ? (_openBlock(), _createBlock(_component_AdminApplicationEditApplication, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              history: _ctx.history,
              "onUpdate:history": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.history) = $event)),
              application: _ctx.application,
              "onUpdate:application": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.application) = $event)),
              "is-work": _ctx.isWork,
              "onUpdate:isWork": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.isWork) = $event)),
              "is-reject": _ctx.isReject,
              "onUpdate:isReject": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.isReject) = $event))
            }, null, 8, ["class", "history", "application", "is-work", "is-reject"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.historyRow.id)
          ? (_openBlock(), _createBlock(_component_HistoryDetails, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              "deposit-withdrawal": _ctx.historyRow,
              onDepositWithdrawal: _ctx.closeHistoryRow
            }, null, 8, ["class", "deposit-withdrawal", "onDepositWithdrawal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.applicationHistory.id)
          ? (_openBlock(), _createBlock(_component_HistoryApplicationDetails, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              application: _ctx.applicationHistory,
              onApplication: _ctx.closeHistoryApplicationHistory
            }, null, 8, ["class", "application", "onApplication"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}