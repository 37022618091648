import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SvgAttribute } from "@/shared/constants/constants";
import { WalletListResponse } from "@/shared/repository/modules/wallet/repo";
import { WalletModalFactory } from "@/shared/repository/modules/wallet/factory";
import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";
import { formatFiatCurrency, formatCryptoCurrency } from "@/shared/utils/amount-helpers";
import { ApplicationStoreRequestFactory } from "@/shared/repository/modules/application/factory";
import { HistoryTab, RoutesName } from "@/shared/constants/enums";
import { HistoryFilterRequestFactory } from "@/shared/factory/history";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    row: {
      type: <PropType<WalletListResponse>>Object,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
      walletRepo: this.$projectServices.walletRepo,
      applicationRepo: this.$projectServices.applicationRepo,
      historyRepo: this.$projectServices.historyRepo,
    };
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedAmount(): string {
      const { type, amount } = this.row;
      const result = type == AdminCurrencyType.fiat ? formatFiatCurrency(amount) : formatCryptoCurrency(amount)
      return result != "0" ? result : "";
    },
  },
  methods: {
    handleDeposit(): void {
      this.walletRepo.UPDATE_MODAL(
        WalletModalFactory({
          type: AdminApplicationType.deposit,
          currencyId: this.row.currency_id,
          amount: this.row.amount,
          isDisabled: true,
        })
      );
    },

    handleWithdrawal(): void {
      this.walletRepo.UPDATE_MODAL(
        WalletModalFactory({
          type: AdminApplicationType.withdrawal,
          currencyId: this.row.currency_id,
          amount: this.row.amount,
          isDisabled: true,
        })
      );
    },

    handleApplication(): void {
      const { type, currency_id, amount } = this.row;

      if (amount) {
        this.applicationRepo.UPDATE_APPLICATION(
          ApplicationStoreRequestFactory({
            currency_id_from: currency_id,
            currency_type_from: type,
          })
        );
      } else {
        this.applicationRepo.UPDATE_APPLICATION(
          ApplicationStoreRequestFactory({
            currency_id_to: currency_id,
            currency_type_to: type,
          })
        );
      }

      this.$router.push(RoutesName.application);
    },

    handleHistory(): void {
      this.historyRepo.UPDATE_TAB(HistoryTab.depositWithdrawal);

      this.historyRepo.UPDATE_FILTER(
        HistoryFilterRequestFactory({
          currency_id: `[${this.row.currency_id}]`,
        })
      );

      this.$router.push(RoutesName.history);
    },
  },
});
