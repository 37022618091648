<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow :class="b()" @close="handleClose">
    <div :class="b('wrapper')">
      <h3 :class="b('title')">Редактирование заявки</h3>

      <div :class="b('fields')">
        <div :class="b('field')">
          <h4 :class="b('field-title')">Статус</h4>

          <SharedSelect
            view="light"
            :options="displayedStatusOptions"
            v-model:value="adminDepositWithdrawalShow.status"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">Этап (Добавляте этапы построчно используя Enter)</h4>

          <UITextArea
            view="light"
            rows="4"
            placeholder="Добавьте комментарий при необходимости"
            v-model="adminDepositWithdrawalShow.stage"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">Изменить сумму сделки</h4>

          <SharedInputCurrency
            placeholder="Введите новую сумму"
            :value="adminDepositWithdrawalShow.amount"
            :currency="adminDepositWithdrawalShow.currency"
            @update:value="handleAmount"
          />
          <p>Пользователь получит: {{ displaedUserAmount }}</p>
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">Изменить объем сделки</h4>

          <SharedInputCurrency
            placeholder="Введите новую сумму"
            :value="adminDepositWithdrawalShow.transaction_volume"
            :currency="adminDepositWithdrawalShow.currency"
            @update:value="handleTransactionVolume"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">Изменить цену исполнения</h4>

          <SharedInputCurrency
            placeholder="Введите новую сумму"
            :value="adminDepositWithdrawalShow.execution_price"
            :currency="adminDepositWithdrawalShow.currency"
            @update:value="handleExecutionPrice"
          />
        </div>
      </div>

      <UIButton
        label="Сохранить"
        view="main"
        :disabled="isDisabled"
        @click="handleSave"
      />
    </div>
  </SharedWindow>
</template>
