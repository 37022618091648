<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('date-time')">
      <p :class="b('label')">
        {{ row.created_at }}
      </p>
    </div>

    <div :class="b('type')">
      <span :class="b('icon')" v-html="displayedSvg[row.app_type]"></span>

      <p :class="b('label', { type: true })">
        {{ row.app_type === "deposit" ? $t("Ввод") : $t("Вывод") }}
      </p>

      <p :class="b('label', { amount: true })">
        {{ displayedAmount }}
      </p>
    </div>

    <div :class="b('amount')">
      <p :class="b('label')">
        {{ displayedAmount }}
      </p>
    </div>

    <div :class="b('currency')">
      <div :class="b('currency-group')">
        <span
          v-if="row.currency.country && row.currency_type === 'fiat'"
          :class="[b('icon'), 'fi', `fi-${row.currency.country}`]"
        ></span>

        <img
          v-else-if="row.currency_type === 'crypto' && row.currency.name"
          :class="b('icon', { crypto: true })"
          :src="
            require(`@/assets/images/crypto/${row.currency.name.toLowerCase()}.svg`)
          "
          alt=""
        />

        <p :class="b('label', { currency: true })">
          {{ row.currency.name }}
        </p>
      </div>

      <span
        :class="b('icon', { status: true })"
        v-html="displayedSvg[row.status]"
      ></span>
    </div>
  </div>
</template>
