import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedWindow, SharedVerification } from "@/components/shared";
import { UIButton } from "@/components/ui";

import { CODE_MAX_LENGTH } from "@/shared/constants/constants";
import { DepositWithdrawalVerificationRequestFactory } from "@/shared/repository/modules/deposit-withdrawal/factory";
import { WalletModalFactory } from "@/shared/repository/modules/wallet/factory";
import { ModalFactory } from "@/shared/factory/modal";
import {
  ModalIcon,
  ModalLabel,
  ModalTitle,
} from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    SharedVerification,
    UIButton,
  },
  data() {
    return {
      isError: false,
      isLoading: false,
      code: "",
      modalRepo: this.$projectServices.modalRepo,
      requisitesRepository: this.$projectServices.requisitesRepository,
      walletRepo: this.$projectServices.walletRepo,
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
      depositWithdrawalRepository:
        this.$projectServices.depositWithdrawalRepository,
    };
  },
  emits: {
    "update": null
  },
  computed: {
    isConfirmDisabled(): boolean {
      return this.isLoading || this.code.length !== CODE_MAX_LENGTH;
    },
  },
  methods: {
    async handleVerification(): Promise<void> {
      try {
        this.isError = false;
        this.isLoading = true;

        await this.depositWithdrawalRepository.verification(
          DepositWithdrawalVerificationRequestFactory({
            code: this.code,
          })
        );

        this.walletRepo.UPDATE_MODAL(WalletModalFactory());
        this.modalRepo.UPDATE_MODAL(
          ModalFactory({
            title: ModalTitle.application,
            label: ModalLabel.applicationWithdrawal,
            icon: ModalIcon.application,
            callback: () => {},
          })
        );
      } catch (error) {
        this.isError = true;
      } finally {
        this.$emit("update")
        this.isLoading = false;
      }
    },
  },
});
