<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <div :class="b('field')">
        <p :class="b('field-label')">
          {{ $t("Название") }}
        </p>

        <UIInput
          view="light"
          v-model="depositWithdrawalStore.new_requisite!.name"
        />
      </div>

      <div :class="b('field')"></div>

      <div :class="b('field')">
        <p :class="b('field-label')">
          {{ $t("Номер расчетного счета") }}
        </p>

        <UIInput
          view="light"
          v-model="depositWithdrawalStore.new_requisite!.account_number"
        />
      </div>

      <div :class="b('field')">
        <p :class="b('field-label')">
          {{ $t("Номер корреспондентского счета") }}
        </p>

        <UIInput
          view="light"
          v-model="depositWithdrawalStore.new_requisite!.correspondent_account"
        />
      </div>

      <div :class="b('field')">
        <p :class="b('field-label')">
          {{ $t("Банковский идентификационный код (БИК)") }}
        </p>

        <UIInput
          view="light"
          v-model="
            depositWithdrawalStore.new_requisite!.bank_identification_code
          "
        />
      </div>

      <div :class="b('field')">
        <p :class="b('field-label')">
          {{ $t("Полное наименование банка") }}
        </p>

        <UIInput
          view="light"
          v-model="depositWithdrawalStore.new_requisite!.bank_name"
        />
      </div>

      <div :class="b('field')">
        <p :class="b('field-label')">
          {{ $t("Идентиф. номер налогоплательщика (ИНН)") }}
        </p>

        <UIInput
          view="light"
          v-model="depositWithdrawalStore.new_requisite!.inn"
        />
      </div>

      <div :class="b('field')">
        <p :class="b('field-label')">
          {{ $t("Код причины постановки на учет (КПП)") }}
        </p>

        <UIInput
          view="light"
          v-model="
            depositWithdrawalStore.new_requisite!.registration_reason_code
          "
        />
      </div>
    </div>

    <div :class="b('footer')">
      <div :class="b('footer-group')">
        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Валюта") }}
          </p>

          <SharedSelect
            view="light"
            :options="displayedCurrencyOptions"
            :icon="displayedCurrencyIcon"
            v-model:value="depositWithdrawalStore.currency_id"
          />
        </div>

        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Сумма") }}
          </p>

          <UIInput
            view="light"
            type="number"
            :is-number="true"
            v-model="depositWithdrawalStore.amount"
          />
        </div>
      </div>

      <div v-if="isCommissionsShow" :class="b('message', { amount: true })">
        <div :class="b('message-item')">
          <p :class="b('message-label')">
            {{ $t("Комиссия") }}
          </p>

          <p :class="b('message-label')">
            {{ displayedCommission }}
            {{
              displayedCurrencyName
            }}
          </p>
        </div>

        <div :class="b('message-item')">
          <p :class="b('message-label')">
            {{ $t("Вы получите") }}
          </p>

          <p :class="b('message-label')">
            ~
            {{ displayedAmount }}
            {{
              displayedCurrencyName
            }}
          </p>
        </div>
      </div>

      <UIButton
        label="Создать заявку"
        view="main"
        :disabled="isDisabled"
        @click="handleCreate"
      />
    </div>
  </div>
</template>
