import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-maska", "placeholder", "value", "disabled", "type", "rows"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return _withDirectives((_openBlock(), _createElementBlock("textarea", {
    "data-maska": _ctx.maska,
    class: _normalizeClass(_ctx.b('', { view: _ctx.view, placeholder: _ctx.placeholderView, error: _ctx.isError })),
    placeholder: _ctx.$t(_ctx.placeholder),
    value: _ctx.modelValue,
    disabled: _ctx.isFieldDisabled,
    type: _ctx.type,
    rows: _ctx.rows,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeModelValue && _ctx.changeModelValue(...args)))
  }, null, 42, _hoisted_1)), [
    [_directive_maska]
  ])
}