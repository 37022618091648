export enum AuthApiRoutes {
  csfr = "/sanctum/csrf-cookie",
  login = "/api/v1/login",
  registration = "/api/v1/register",
  emailCode = "/api/v1/email-code",
  codeVerification = "/api/v1/code-verification",
  forgot = "/api/v1/forgot-password",
  logout = "/api/v1/logout",
  retryNotification = "/api/v1/retry-notification",
  parseToken = "api/v1/parse/token"
}

export interface AuthProjectUrlGenerator {}

export class AuthUrlGenerator implements AuthProjectUrlGenerator {
  csfr(): string {
    return AuthApiRoutes.csfr;
  }

  login(): string {
    return AuthApiRoutes.login;
  }

  registration(): string {
    return AuthApiRoutes.registration;
  }

  emailCode(): string {
    return AuthApiRoutes.emailCode;
  }

  codeVerification(): string {
    return AuthApiRoutes.codeVerification;
  }

  forgot(): string {
    return AuthApiRoutes.forgot;
  }

  logout(): string {
    return AuthApiRoutes.logout;
  }

  retryNotification(): string {
    return AuthApiRoutes.retryNotification;
  }

  parseToken(): string {
    return AuthApiRoutes.parseToken;
  }
}
