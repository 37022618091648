import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { AdminConfigCommissionField } from "../field";

import { SharedValidate, SharedInputDouble } from "@/components/shared";
import { UIButton } from "@/components/ui";

import { AdminCommissionExchangeType } from "@/shared/repository/modules/admin/commission/enums";
import {
  AdminCommissionDepositWithdrawalListResponseFactory,
  AdminCommissionDepositWithdrawalStoreFieldsFactory,
  AdminCommissionDepositWithdrawalStoreRequestFactory,
  AdminCommissionDepositWithdrawalUpdateRequestFactory,
} from "@/shared/repository/modules/admin/commission/factory";
import {
  ModalActionLabel,
  ModalIcon,
  ModalLabel,
  ModalTitle,
} from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminConfigCommissionField,
    SharedValidate,
    SharedInputDouble,
    UIButton,
  },
  props: {
    type: {
      type: <PropType<AdminCommissionExchangeType>>String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isValidate: false,
      isLoading: false,
      commissionDepositWithdrawalStoreRequest:
        AdminCommissionDepositWithdrawalListResponseFactory(),
      commissionDepositWithdrawalStoreFields:
        AdminCommissionDepositWithdrawalStoreFieldsFactory(),
      adminCommissionRepository:
        this.$projectServices.adminCommissionRepository,
      usersRepo: this.$projectServices.usersRepo,
      modalRepo: this.$projectServices.modalRepo,
    };
  },
  async created(): Promise<void> {
    await this.handleDepositWithdrawalList();
  },
  watch: {
    async type(): Promise<void> {
      this.commissionDepositWithdrawalStoreRequest =
        AdminCommissionDepositWithdrawalListResponseFactory();
      this.$emit("update:userId", 0);

      if (this.type === AdminCommissionExchangeType.user && this.userId) {
        await this.handleDepositWithdrawalList();
      } else if (this.type === AdminCommissionExchangeType.all) {
        await this.handleDepositWithdrawalList();
      }
    },

    async userId(): Promise<void> {
      if (this.type === AdminCommissionExchangeType.user && this.userId) {
        await this.handleDepositWithdrawalList();
      }
    },
  },
  computed: {
    isDisabled(): boolean {
      return Object.values(this.commissionDepositWithdrawalStoreFields).some(
        (field) => !field
      );
    },

    isSaveDisabled(): boolean {
      return this.type === AdminCommissionExchangeType.all
        ? this.isLoading
        : this.isLoading || !this.userId;
    },

    isRemoveDisabled(): boolean {
      return this.type != AdminCommissionExchangeType.user || (this.isLoading || !this.userId || !this.commissionDepositWithdrawalStoreRequest.id)
    }
  },
  methods: {
    async handleDepositWithdrawalList(): Promise<void> {
      try {
        this.isLoading = true;

        this.commissionDepositWithdrawalStoreRequest =
          await this.adminCommissionRepository.depositWithdrawalList(
            this.type,
            this.type === AdminCommissionExchangeType.user ? this.userId : 0
          );
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async handleSave(): Promise<void> {
      try {
        this.isLoading = true;

        if (this.isDisabled) {
          this.isValidate = true;

          return;
        }

        if (this.commissionDepositWithdrawalStoreRequest.id) {
          await this.adminCommissionRepository.depositWithdrawalUpdate(
            this.commissionDepositWithdrawalStoreRequest.id,
            AdminCommissionDepositWithdrawalUpdateRequestFactory(
              this.commissionDepositWithdrawalStoreRequest
            )
          );
        } else {
          await this.adminCommissionRepository.depositWithdrawalStore(
            this.type,
            AdminCommissionDepositWithdrawalStoreRequestFactory({
              ...this.commissionDepositWithdrawalStoreRequest,
              user_id:
                this.type === AdminCommissionExchangeType.user
                  ? this.userId
                  : 0,
            })
          );
        }

        this.modalRepo.UPDATE_MODAL({
          title: ModalTitle.exchange,
          label: ModalLabel.default,
          icon: ModalIcon.adminBalanceUpdate,
          actionLabel: ModalActionLabel.ok,
          callback: () => {
            this.handleDepositWithdrawalList()
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async handleDeleteDepositWithdrawCommission() {
      if (this.commissionDepositWithdrawalStoreRequest.id) {
        try {
          this.isLoading = true;
          this.isLoading = true;
          await this.adminCommissionRepository.depositWithdrawalDelete(this.commissionDepositWithdrawalStoreRequest.id)
          
          this.modalRepo.UPDATE_MODAL({
            title: ModalTitle.exchangeRemove,
            label: ModalLabel.default,
            icon: ModalIcon.adminBalanceUpdate,
            actionLabel: ModalActionLabel.ok,
            callback: () => {
              this.handleDepositWithdrawalList()
            },
          });
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }
    }
  },
});
