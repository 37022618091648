<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <textarea
    v-maska
    :data-maska="maska"
    :class="b('', { view, placeholder: placeholderView, error: isError })"
    :placeholder="$t(placeholder)"
    :value="modelValue"
    :disabled="isFieldDisabled"
    :type="type"
    :rows="rows"
    @input="changeModelValue"
  />
</template>