<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('container')">
      <table :class="b('table')">
        <thead :class="b('table-header')">
          <tr>
            <th v-for="column in displayedColumns" :key="column">
              <p :class="b('column-label')" v-html="$t(column)" />
            </th>
          </tr>
        </thead>

        <tbody>
          <component
            :is="displayedRow"
            :class="b('row')"
            v-for="row in displayedHistory"
            :key="row.id"
            :row="row"
            :deposit-withdrawal="depositWithdrawal"
            :is-work="isWork"
            :is-reject="isReject"
            @history="handleHistory"
            @depositWithdrawal="handleDepositWithdrawal"
            @detailHistory="showDetailHistory"
            @applicationHistory="showApplicationHistory"
            @application="handleApplication"
            @work="handleWork"
            @reject="handleReject"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>
