import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedInputDouble = _resolveComponent("SharedInputDouble")!
  const _component_AdminConfigCommissionField = _resolveComponent("AdminConfigCommissionField")!
  const _component_SharedValidate = _resolveComponent("SharedValidate")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createVNode(_component_SharedValidate, {
        field: "deposit_fiat_commission",
        value: _ctx.commissionDepositWithdrawalStoreRequest.deposit_fiat_commission,
        "is-validate": _ctx.isValidate,
        "field-value": 
          _ctx.commissionDepositWithdrawalStoreFields.deposit_fiat_commission
        ,
        "onUpdate:fieldValue": _cache[1] || (_cache[1] = ($event: any) => ((
          _ctx.commissionDepositWithdrawalStoreFields.deposit_fiat_commission
        ) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AdminConfigCommissionField, { title: "Комиссия на ввод (Фиат)" }, {
            default: _withCtx(() => [
              _createVNode(_component_SharedInputDouble, {
                view: "light",
                modelValue: 
              _ctx.commissionDepositWithdrawalStoreRequest.deposit_fiat_commission
            ,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((
              _ctx.commissionDepositWithdrawalStoreRequest.deposit_fiat_commission
            ) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "is-validate", "field-value"]),
      _createVNode(_component_SharedValidate, {
        field: "withdrawal_fiat_commission",
        value: 
          _ctx.commissionDepositWithdrawalStoreRequest.withdrawal_fiat_commission
        ,
        "is-validate": _ctx.isValidate,
        "field-value": 
          _ctx.commissionDepositWithdrawalStoreFields.withdrawal_fiat_commission
        ,
        "onUpdate:fieldValue": _cache[3] || (_cache[3] = ($event: any) => ((
          _ctx.commissionDepositWithdrawalStoreFields.withdrawal_fiat_commission
        ) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AdminConfigCommissionField, { title: "Комиссия на вывод (Фиат)" }, {
            default: _withCtx(() => [
              _createVNode(_component_SharedInputDouble, {
                view: "light",
                modelValue: 
              _ctx.commissionDepositWithdrawalStoreRequest.withdrawal_fiat_commission
            ,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((
              _ctx.commissionDepositWithdrawalStoreRequest.withdrawal_fiat_commission
            ) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "is-validate", "field-value"]),
      _createVNode(_component_SharedValidate, {
        field: "deposit_crypto_commission",
        value: 
          _ctx.commissionDepositWithdrawalStoreRequest.deposit_crypto_commission
        ,
        "is-validate": _ctx.isValidate,
        "field-value": 
          _ctx.commissionDepositWithdrawalStoreFields.deposit_crypto_commission
        ,
        "onUpdate:fieldValue": _cache[5] || (_cache[5] = ($event: any) => ((
          _ctx.commissionDepositWithdrawalStoreFields.deposit_crypto_commission
        ) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AdminConfigCommissionField, { title: "Комиссия на ввод (Крипто)" }, {
            default: _withCtx(() => [
              _createVNode(_component_SharedInputDouble, {
                view: "light",
                modelValue: 
              _ctx.commissionDepositWithdrawalStoreRequest.deposit_crypto_commission
            ,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((
              _ctx.commissionDepositWithdrawalStoreRequest.deposit_crypto_commission
            ) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "is-validate", "field-value"]),
      _createVNode(_component_SharedValidate, {
        field: "withdrawal_crypto_commission",
        value: 
          _ctx.commissionDepositWithdrawalStoreRequest.withdrawal_crypto_commission
        ,
        "is-validate": _ctx.isValidate,
        "field-value": 
          _ctx.commissionDepositWithdrawalStoreFields.withdrawal_crypto_commission
        ,
        "onUpdate:fieldValue": _cache[7] || (_cache[7] = ($event: any) => ((
          _ctx.commissionDepositWithdrawalStoreFields.withdrawal_crypto_commission
        ) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AdminConfigCommissionField, { title: "Комиссия на вывод (Крипто)" }, {
            default: _withCtx(() => [
              _createVNode(_component_SharedInputDouble, {
                view: "light",
                modelValue: 
              _ctx.commissionDepositWithdrawalStoreRequest.withdrawal_crypto_commission
            ,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((
              _ctx.commissionDepositWithdrawalStoreRequest.withdrawal_crypto_commission
            ) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "is-validate", "field-value"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('control'))
    }, [
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('save')),
        label: "Сохранить",
        view: "main",
        disabled: _ctx.isSaveDisabled,
        onClick: _ctx.handleSave
      }, null, 8, ["class", "disabled", "onClick"]),
      (!_ctx.isRemoveDisabled)
        ? (_openBlock(), _createBlock(_component_UIButton, {
            key: 0,
            class: _normalizeClass(_ctx.b('remove')),
            label: "Удалить",
            view: "main-outline",
            onClick: _ctx.handleDeleteDepositWithdrawCommission
          }, null, 8, ["class", "onClick"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}