import { defineComponent, PropType } from "vue";
import moment from "moment";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SvgAttribute } from "@/shared/constants/constants";
import { AdminDepositWithdrawalListResponse } from "@/shared/repository/modules/admin/deposit-withdrawal/repo";
import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";
import { AdminDepositWithdrawalStatus } from "@/shared/repository/modules/admin/deposit-withdrawal/enums";
import {
  formatCryptoCurrency,
  formatFiatCurrency,
} from "@/shared/utils/amount-helpers";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";

enum OptionType {
  work = "work",
  edit = "edit",
  reject = "reject",
}

interface Option {
  id: number;
  label: string;
  isDisabled: boolean;
  type: OptionType;
}

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    row: {
      type: <PropType<AdminDepositWithdrawalListResponse>>Object,
      required: true,
    },
    depositWithdrawal: {
      type: <PropType<AdminDepositWithdrawalListResponse>>Object,
      required: true,
    },
    isWork: {
      type: Boolean,
      default: false,
    },
    isReject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      adminDepositWithdrawalRepository:
        this.$projectServices.adminDepositWithdrawalRepository,
      userRepo: this.$projectServices.userRepo,
    };
  },
  emits: {
    history: null,
    depositWithdrawal: null,
    applicationHistory: null,
    work: null,
    reject: null,
  },
  computed: {
    displayedSvg(): SvgAttribute {
      return SVG;
    },

    displayedCreatedAt(): string {
      return moment(this.row.created_at).format("YYYY-MM-DD HH:mm:ss");
    },

    displayedAppType(): string {
      if (this.isDeposit) return "Пополнение";
      else if (this.isWithdrawal) return "Вывод";

      return "";
    },

    displayedStatusIcon(): string {
      return SVG[this.row.status];
    },

    displayedAmount(): string {
      const { currency_type, amount } = this.row;

      return currency_type === AdminCurrencyType.fiat
        ? formatFiatCurrency(amount)
        : formatCryptoCurrency(amount);
    },

    displayedStatus(): string {
      switch (this.row.status) {
        case AdminDepositWithdrawalStatus.pending:
          return "Создано";
        case AdminDepositWithdrawalStatus.waiting:
          return "Ожидание подтверждения";
        case AdminDepositWithdrawalStatus.reject:
          return "Отклонено";
        case AdminDepositWithdrawalStatus.in_work:
          return "В работе";
        case AdminDepositWithdrawalStatus.completed:
          return "Выполнено";
        default:
          return "";
      }
    },

    displayedOptions(): Option[] {
      const { status } = this.row;

      return [
        {
          id: 0,
          label: "Взять в работу",
          type: OptionType.work,
          isDisabled: this.isLoading,
        },
        {
          id: 1,
          label: "Редактировать",
          type: OptionType.edit,
          isDisabled:
            this.isLoading || status === AdminDepositWithdrawalStatus.pending,
        },
        {
          id: 2,
          label: "Отклонить",
          type: OptionType.reject,
          isDisabled: this.isLoading,
        },
      ];
    },

    isDeposit(): boolean {
      return this.row.app_type === AdminApplicationType.deposit;
    },

    isWithdrawal(): boolean {
      return this.row.app_type === AdminApplicationType.withdrawal;
    },

    isDisabled(): boolean {
      return this.isLoading;
    },

    isStageGroupShow(): boolean {
      return this.row.status === AdminDepositWithdrawalStatus.pending;
    },

    isActionShow(): boolean {
      return !(
        this.row.status === AdminDepositWithdrawalStatus.reject ||
        this.row.status === AdminDepositWithdrawalStatus.completed
      );
    },

    isAdminShow(): boolean {
      if (this.row.admin.id) {
        return this.userRepo.userInfo.id !== this.row.admin.id;
      }

      return false;
    },
  },
  methods: {
    handleToggle(): void {
      this.isVisible = !this.isVisible;
    },

    handleHide(): void {
      this.isVisible = false;
    },

    handleInWork(): void {
      this.handleHide();

      this.$emit("work", true);
      this.$emit("reject", false);

      this.$emit("depositWithdrawal", this.row);
    },

    handleOption({ type }: Option): void {
      switch (type) {
        case OptionType.work:
          this.$emit("work", true);
          this.$emit("reject", false);

          break;
        case OptionType.edit:
          this.$emit("work", false);
          this.$emit("reject", false);

          break;
        case OptionType.reject:
          this.$emit("work", false);
          this.$emit("reject", true);

          break;
        default:
          break;
      }

      this.$emit("depositWithdrawal", this.row);

      this.handleHide();
    },

    async handleRemove(): Promise<void> {
      try {
        this.isLoading = true;

        await this.adminDepositWithdrawalRepository.delete(this.row.id);

        this.$emit(
          "history",
          await this.adminDepositWithdrawalRepository.list()
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    handleRow(): void {
      this.$emit("applicationHistory", this.row);
    },
  },
});
