import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('container'))
    }, [
      _createElementVNode("table", {
        class: _normalizeClass(_ctx.b('table'))
      }, [
        _createElementVNode("thead", {
          class: _normalizeClass(_ctx.b('table-header'))
        }, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedColumns, (column) => {
              return (_openBlock(), _createElementBlock("th", { key: column }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('column-label')),
                  innerHTML: _ctx.$t(column)
                }, null, 10, _hoisted_1)
              ]))
            }), 128))
          ])
        ], 2),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedHistory, (row) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedRow), {
              class: _normalizeClass(_ctx.b('row')),
              key: row.id,
              row: row,
              "deposit-withdrawal": _ctx.depositWithdrawal,
              "is-work": _ctx.isWork,
              "is-reject": _ctx.isReject,
              onHistory: _ctx.handleHistory,
              onDepositWithdrawal: _ctx.handleDepositWithdrawal,
              onDetailHistory: _ctx.showDetailHistory,
              onApplicationHistory: _ctx.showApplicationHistory,
              onApplication: _ctx.handleApplication,
              onWork: _ctx.handleWork,
              onReject: _ctx.handleReject
            }, null, 40, ["class", "row", "deposit-withdrawal", "is-work", "is-reject", "onHistory", "onDepositWithdrawal", "onDetailHistory", "onApplicationHistory", "onApplication", "onWork", "onReject"]))
          }), 128))
        ])
      ], 2)
    ], 2)
  ], 2))
}