import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      _createElementVNode("h2", {
        class: _normalizeClass(_ctx.b('title'))
      }, _toDisplayString(_ctx.$t("Поздравляем с успешной верификацией")), 3),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('logo')),
        innerHTML: _ctx.displayedIcons.logo
      }, null, 10, _hoisted_1),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('logo', { small: true })),
        innerHTML: _ctx.displayedIcons.logoSmall
      }, null, 10, _hoisted_2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('list'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('item'))
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t("Уведомляем вас об успешном открытии")) + " ", 1),
          _createElementVNode("a", {
            class: _normalizeClass(_ctx.b('item-link')),
            href: ""
          }, _toDisplayString(_ctx.$t("брокерского счета")), 3),
          _createTextVNode(" . ")
        ], 2),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('item'))
        }, _toDisplayString(_ctx.$t(
              "Для продолжения работы ознакомьтесь с нашими внутренними документами:"
            )), 3),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('item'))
        }, [
          _createTextVNode(" - "),
          _createElementVNode("a", {
            class: _normalizeClass(_ctx.b('item-link')),
            href: ""
          }, _toDisplayString(_ctx.$t("Политика AML")), 3)
        ], 2),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('item'))
        }, [
          _createTextVNode(" - "),
          _createElementVNode("a", {
            class: _normalizeClass(_ctx.b('item-link')),
            href: ""
          }, _toDisplayString(_ctx.$t("Лицензия")), 3)
        ], 2),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('item'))
        }, [
          _createTextVNode(" - "),
          _createElementVNode("a", {
            class: _normalizeClass(_ctx.b('item-link')),
            href: ""
          }, _toDisplayString(_ctx.$t("Инвестиционная декларация")), 3)
        ], 2),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('item'))
        }, [
          _createTextVNode(" - "),
          _createElementVNode("a", {
            class: _normalizeClass(_ctx.b('item-link')),
            href: ""
          }, _toDisplayString(_ctx.$t(
                "Регламент о порядке осуществления деятельности на рынке ценных бумаг и срочном рынке"
              )), 3)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('footer'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('footer-label'))
        }, _toDisplayString(_ctx.$t(
              "Нажимая “Продолжить”, вы соглашаетесь с вышеуказанными документами"
            )), 3),
        _createVNode(_component_UIButton, {
          class: _normalizeClass(_ctx.b('footer-next')),
          label: "Продолжить",
          view: "main",
          onClick: _ctx.handleNext
        }, null, 8, ["class", "onClick"])
      ], 2)
    ], 2)
  ], 2))
}