import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { ApplicationHistoryResponse } from "@/shared/repository/modules/application/repo";
import { SvgAttribute } from "@/shared/constants/constants";
import { formatFiatCurrency, formatCryptoCurrency } from "@/shared/utils/amount-helpers";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    row: {
      type: <PropType<ApplicationHistoryResponse>>Object,
      required: true,
    },
  },
  computed: {
    displayedSvg(): SvgAttribute {
      return SVG;
    },

    displayedAmountFrom(): string {
      return formatFiatCurrency(this.row.amount);
    },

    displayedAmountTo(): string {
      if (this.row.currency_type_to === AdminCurrencyType.fiat) {
        return formatFiatCurrency((this.row.amount - this.row.commissions) * this.row.execution_price);
      } else {
        return formatCryptoCurrency((this.row.amount - this.row.commissions) * this.row.execution_price);
      }
    },
  },
});
