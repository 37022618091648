import { AxiosInstance } from "axios";

import { RequisitesUrlGenerator } from "./url-generator";
import {
  RequisitesBankRequest,
  RequisitesBankResponse,
  RequisitesBankUpdateRequest,
  RequisitesCryptoRequest,
  RequisitesCryptoResponse,
  RequisitesCryptoUpdateRequest,
  RequisitesVerificationRequest,
} from "./repo";
import {
  RequisitesBankResponseFactory,
  RequisitesCryptoResponseFactory,
} from "./factory";

import { ResponseData } from "@/shared/constants/interfaces";

export interface RequisitesProjectRepository {
  bank(
    payload: RequisitesBankRequest,
    userId: number
  ): Promise<RequisitesBankResponse>;
  bankDeposit(
    payload: RequisitesBankRequest,
    userId: number,
    currencyId: number,
    amount: number,
    appType: string,
    currencyType: string,
    userRequisiteId: number | null,
    userRequisiteType: string | null,
    newRequisite: null
  ): Promise<RequisitesBankResponse>;
  bankUpdate(
    id: number,
    payload: RequisitesBankUpdateRequest
  ): Promise<RequisitesBankResponse>;
  bankDelete(id: number, userId: number): Promise<void>;
  bankShow(id: number, userId: number): Promise<RequisitesBankResponse>;
  bankList(userId: number): Promise<RequisitesBankResponse[]>;

  crypto(
    payload: RequisitesCryptoRequest,
    userId: number
  ): Promise<RequisitesCryptoResponse>;
  cryptoUpdate(
    id: number,
    payload: RequisitesCryptoUpdateRequest
  ): Promise<RequisitesCryptoResponse>;
  cryptoDelete(id: number, userId: number): Promise<void>;
  cryptoShow(id: number, userId: number): Promise<RequisitesCryptoResponse>;
  cryptoList(userId: number): Promise<RequisitesCryptoResponse[]>;

  verification(payload: RequisitesVerificationRequest): Promise<void>;
}

export class RequisitesHttpRepo implements RequisitesProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: RequisitesUrlGenerator
  ) {}

  async bank(
    payload: RequisitesBankRequest,
    userId: number
  ): Promise<RequisitesBankResponse> {
    const { data } = await this.axios.post<
      ResponseData<RequisitesBankResponse>
    >(this.urlGenerator.bank(), {
      user_id: userId,
      ...payload,
    });

    return RequisitesBankResponseFactory(data.data);
  }

  async bankDeposit(
    payload: RequisitesBankRequest,
    userId: number,
    currencyId: number,
    amount: number,
    appType: string,
    currencyType: string,
    userRequisiteId: number | null,
    userRequisiteType: string | null,
    newRequisite: null
  ): Promise<RequisitesBankResponse> {
    const { data } = await this.axios.post<
      ResponseData<RequisitesBankResponse>
    >(this.urlGenerator.bank(), {
      user_id: userId,
      currency_id: currencyId,
      amount: amount,
      app_type: appType,
      currency_type: currencyType,
      user_requisite_id: userRequisiteId,
      user_requisite_type: userRequisiteType,
      new_requisite: newRequisite,
      ...payload,
    });

    return RequisitesBankResponseFactory(data.data);
  }

  async bankUpdate(
    id: number,
    payload: RequisitesBankUpdateRequest
  ): Promise<RequisitesBankResponse> {
    const { data } = await this.axios.patch<
      ResponseData<RequisitesBankResponse>
    >(this.urlGenerator.bankUpdate(id), payload);

    return RequisitesBankResponseFactory(data.data);
  }

  async bankDelete(id: number, userId: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.bankDelete(userId, id));
  }

  async bankShow(id: number, userId: number): Promise<RequisitesBankResponse> {
    const { data } = await this.axios.get<ResponseData<RequisitesBankResponse>>(
      this.urlGenerator.bankShow(userId, id)
    );

    return RequisitesBankResponseFactory(data.data);
  }

  async bankList(userId: number): Promise<RequisitesBankResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<RequisitesBankResponse[]>
    >(this.urlGenerator.bankList(userId));

    return data.data.map((item) => RequisitesBankResponseFactory(item));
  }

  async crypto(
    payload: RequisitesCryptoRequest,
    userId: number
  ): Promise<RequisitesCryptoResponse> {
    const { data } = await this.axios.post<
      ResponseData<RequisitesCryptoResponse>
    >(this.urlGenerator.crypto(), {
      user_id: userId,
      ...payload,
    });

    return RequisitesCryptoResponseFactory(data.data);
  }

  async cryptoUpdate(
    id: number,
    payload: RequisitesCryptoUpdateRequest
  ): Promise<RequisitesCryptoResponse> {
    const { data } = await this.axios.patch<
      ResponseData<RequisitesCryptoResponse>
    >(this.urlGenerator.cryptoUpdate(id), payload);

    return RequisitesCryptoResponseFactory(data.data);
  }

  async cryptoDelete(id: number, userId: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.cryptoDelete(userId, id));
  }

  async cryptoShow(
    id: number,
    userId: number
  ): Promise<RequisitesCryptoResponse> {
    const { data } = await this.axios.get<
      ResponseData<RequisitesCryptoResponse>
    >(this.urlGenerator.cryptoShow(userId, id));

    return RequisitesCryptoResponseFactory(data.data);
  }

  async cryptoList(userId: number): Promise<RequisitesCryptoResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<RequisitesCryptoResponse[]>
    >(this.urlGenerator.cryptoList(userId));

    return data.data.map((item) => RequisitesCryptoResponseFactory(item));
  }

  async verification(payload: RequisitesVerificationRequest): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.verification(), payload);
  }
}
