import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedInputDouble = _resolveComponent("SharedInputDouble")!
  const _component_AdminConfigCommissionField = _resolveComponent("AdminConfigCommissionField")!
  const _component_SharedValidate = _resolveComponent("SharedValidate")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createVNode(_component_SharedValidate, {
        field: "mex_commission",
        value: _ctx.commissionExchangeStoreRequest.mex_commission,
        "is-validate": _ctx.isValidate,
        "field-value": _ctx.commissionExchangeStoreFields.mex_commission,
        "onUpdate:fieldValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.commissionExchangeStoreFields.mex_commission) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AdminConfigCommissionField, { title: "Комиссия MEX" }, {
            default: _withCtx(() => [
              _createVNode(_component_SharedInputDouble, {
                view: "light",
                modelValue: _ctx.commissionExchangeStoreRequest.mex_commission,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.commissionExchangeStoreRequest.mex_commission) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "is-validate", "field-value"]),
      _createVNode(_component_SharedValidate, {
        field: "moex_commission",
        value: _ctx.commissionExchangeStoreRequest.moex_commission,
        "is-validate": _ctx.isValidate,
        "field-value": _ctx.commissionExchangeStoreFields.moex_commission,
        "onUpdate:fieldValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.commissionExchangeStoreFields.moex_commission) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AdminConfigCommissionField, { title: "Комиссия MOEX" }, {
            default: _withCtx(() => [
              _createVNode(_component_SharedInputDouble, {
                view: "light",
                modelValue: _ctx.commissionExchangeStoreRequest.moex_commission,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.commissionExchangeStoreRequest.moex_commission) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "is-validate", "field-value"]),
      _createVNode(_component_SharedValidate, {
        field: "swift",
        value: _ctx.commissionExchangeStoreRequest.swift,
        "is-validate": _ctx.isValidate,
        "field-value": _ctx.commissionExchangeStoreFields.swift,
        "onUpdate:fieldValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.commissionExchangeStoreFields.swift) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AdminConfigCommissionField, { title: "SWIFT" }, {
            default: _withCtx(() => [
              _createVNode(_component_SharedInputDouble, {
                view: "light",
                modelValue: _ctx.commissionExchangeStoreRequest.swift,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.commissionExchangeStoreRequest.swift) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "is-validate", "field-value"]),
      _createVNode(_component_SharedValidate, {
        field: "crypto_exchange_commission",
        value: _ctx.commissionExchangeStoreRequest.crypto_exchange_commission,
        "is-validate": _ctx.isValidate,
        "field-value": 
          _ctx.commissionExchangeStoreFields.crypto_exchange_commission
        ,
        "onUpdate:fieldValue": _cache[7] || (_cache[7] = ($event: any) => ((
          _ctx.commissionExchangeStoreFields.crypto_exchange_commission
        ) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AdminConfigCommissionField, { title: "Комиссия Крипто биржи" }, {
            default: _withCtx(() => [
              _createVNode(_component_SharedInputDouble, {
                view: "light",
                modelValue: _ctx.commissionExchangeStoreRequest.crypto_exchange_commission,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.commissionExchangeStoreRequest.crypto_exchange_commission) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "is-validate", "field-value"]),
      _createVNode(_component_SharedValidate, {
        field: "blockchain_transfers_commission",
        value: _ctx.commissionExchangeStoreRequest.blockchain_transfers_commission,
        "is-validate": _ctx.isValidate,
        "field-value": 
          _ctx.commissionExchangeStoreFields.blockchain_transfers_commission
        ,
        "onUpdate:fieldValue": _cache[9] || (_cache[9] = ($event: any) => ((
          _ctx.commissionExchangeStoreFields.blockchain_transfers_commission
        ) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AdminConfigCommissionField, { title: "Блокчейн перевод" }, {
            default: _withCtx(() => [
              _createVNode(_component_SharedInputDouble, {
                view: "light",
                modelValue: 
              _ctx.commissionExchangeStoreRequest.blockchain_transfers_commission
            ,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((
              _ctx.commissionExchangeStoreRequest.blockchain_transfers_commission
            ) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "is-validate", "field-value"]),
      _createVNode(_component_SharedValidate, {
        field: "volatility",
        value: _ctx.commissionExchangeStoreRequest.volatility,
        "is-validate": _ctx.isValidate,
        "field-value": _ctx.commissionExchangeStoreFields.volatility,
        "onUpdate:fieldValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.commissionExchangeStoreFields.volatility) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AdminConfigCommissionField, { title: "Волатильность" }, {
            default: _withCtx(() => [
              _createVNode(_component_SharedInputDouble, {
                view: "light",
                modelValue: _ctx.commissionExchangeStoreRequest.volatility,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.commissionExchangeStoreRequest.volatility) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "is-validate", "field-value"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('control'))
    }, [
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('save')),
        label: "Сохранить",
        view: "main",
        disabled: _ctx.isSaveDisabled,
        onClick: _ctx.handleSave
      }, null, 8, ["class", "disabled", "onClick"]),
      (!_ctx.isRemoveDisabled)
        ? (_openBlock(), _createBlock(_component_UIButton, {
            key: 0,
            class: _normalizeClass(_ctx.b('remove')),
            label: "Удалить",
            view: "main-outline",
            onClick: _ctx.handleExchangeCommission
          }, null, 8, ["class", "onClick"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}