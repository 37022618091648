import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.b()),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleRow && _ctx.handleRow(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('date-time'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label', { dateTime: true }))
      }, _toDisplayString(_ctx.row.created_at), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('type'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('operation'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label', { from: true }))
        }, _toDisplayString(_ctx.displayedAmountFrom), 3),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.row.currency_from.name.toUpperCase()), 3)
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('icon', { chevron: true })),
        innerHTML: _ctx.displayedSvg.chevron
      }, null, 10, _hoisted_1),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('operation'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label', { to: true }))
        }, _toDisplayString(_ctx.displayedAmountTo), 3),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.row.currency_to.name.toUpperCase()), 3)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('commission'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label'))
      }, _toDisplayString(_ctx.displayedExecutionPrice), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('volume'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label'))
      }, _toDisplayString(_ctx.displayedTransactionVolume), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('commission'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label'))
      }, _toDisplayString(_ctx.displayedCommission), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('id'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label'))
      }, _toDisplayString(_ctx.row.id), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('status'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('group'))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('icon')),
          innerHTML: _ctx.displayedSvg[_ctx.row.status]
        }, null, 10, _hoisted_2)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('commission'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label'))
      }, _toDisplayString(_ctx.displayLastStatus(_ctx.row.stage)), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('block'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('block-group'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('block-operations'))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('label'))
          }, "Операция", 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('block-type'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('block-operation'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('label', { from: true }))
              }, _toDisplayString(_ctx.displayedAmountFrom), 3),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('label'))
              }, _toDisplayString(_ctx.row.currency_from.name.toUpperCase()), 3)
            ], 2),
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('icon', { chevron: true })),
              innerHTML: _ctx.displayedSvg.chevron
            }, null, 10, _hoisted_3),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('operation'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('label', { to: true }))
              }, _toDisplayString(_ctx.displayedAmountTo), 3),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('label'))
              }, _toDisplayString(_ctx.row.currency_to.name.toUpperCase()), 3)
            ], 2)
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('block-date'))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('label', { dateTime: true }))
          }, _toDisplayString(_ctx.row.created_at), 3),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('icon')),
            innerHTML: _ctx.displayedSvg[_ctx.row.status]
          }, null, 10, _hoisted_4)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('block-group'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('block-commission'))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('label'))
          }, "Цена", 2),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('label'))
          }, _toDisplayString(_ctx.displayedExecutionPrice), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('block-other'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('block-number'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('label'))
            }, _toDisplayString(_ctx.$t('№ сделки')), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('label'))
            }, _toDisplayString(_ctx.row.id), 3)
          ], 2)
        ], 2)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('block-actions'))
    }, [
      (_ctx.isRemoveShow)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(_ctx.b('action')),
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleRemove && _ctx.handleRemove(...args)), ["stop"]))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('icon', { remove: true })),
              innerHTML: _ctx.displayedSvg.remove
            }, null, 10, _hoisted_5)
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}