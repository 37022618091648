import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SharedLanguage } from "@/components/shared";

import { SvgAttribute } from "@/shared/constants/constants";
import { UserInfoResponse } from "@/shared/repository/modules/user/repo";
import { RoutesName } from "@/shared/constants/enums";
import { WalletListResponse } from "@/shared/repository/modules/wallet/repo";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";
import { WalletListResponseFactory } from "@/shared/repository/modules/wallet/factory";
import { formatFiatCurrency } from "@/shared/utils/amount-helpers";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedLanguage,
  },
  data() {
    return {
      isLoading: false,
      userRepo: this.$projectServices.userRepo,
      walletRepo: this.$projectServices.walletRepo,
      authRepository: this.$projectServices.authRepository,
    };
  },
  computed: {
    displayedCurrency(): WalletListResponse {
      const list = this.walletRepo.list;

      if (list.length) {
        return list[0].type === AdminCurrencyType.fiat
          ? {
              ...list[0],
              amount: formatFiatCurrency(list[0].amount) as unknown as number,
            }
          : WalletListResponseFactory();
      }

      return WalletListResponseFactory();
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedUserInfo(): UserInfoResponse {
      return this.userRepo.userInfo;
    },

    isLogoutDisabled(): boolean {
      return this.isLoading;
    },

    isVerified(): boolean {
      return this.userRepo.isVerified;
    },
  },
  methods: {
    async handleLogout(): Promise<void> {
      this.isLoading = true;
      try {
        await this.authRepository.logout();
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
        this.$router.push({ name: RoutesName.login });
      }
    },
  },
});
