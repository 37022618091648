<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <SharedValidate
        field="mex_commission"
        :value="commissionExchangeStoreRequest.mex_commission"
        :is-validate="isValidate"
        v-model:field-value="commissionExchangeStoreFields.mex_commission"
      >
        <AdminConfigCommissionField title="Комиссия MEX">
          <SharedInputDouble
            view="light"
            v-model="commissionExchangeStoreRequest.mex_commission"
          />
        </AdminConfigCommissionField>
      </SharedValidate>

      <SharedValidate
        field="moex_commission"
        :value="commissionExchangeStoreRequest.moex_commission"
        :is-validate="isValidate"
        v-model:field-value="commissionExchangeStoreFields.moex_commission"
      >
        <AdminConfigCommissionField title="Комиссия MOEX">
          <SharedInputDouble
            view="light"
            v-model="commissionExchangeStoreRequest.moex_commission"
          />
        </AdminConfigCommissionField>
      </SharedValidate>

      <SharedValidate
        field="swift"
        :value="commissionExchangeStoreRequest.swift"
        :is-validate="isValidate"
        v-model:field-value="commissionExchangeStoreFields.swift"
      >
        <AdminConfigCommissionField title="SWIFT">
          <SharedInputDouble
            view="light"
            v-model="commissionExchangeStoreRequest.swift"
          />
        </AdminConfigCommissionField>
      </SharedValidate>

      <SharedValidate
        field="crypto_exchange_commission"
        :value="commissionExchangeStoreRequest.crypto_exchange_commission"
        :is-validate="isValidate"
        v-model:field-value="
          commissionExchangeStoreFields.crypto_exchange_commission
        "
      >
        <AdminConfigCommissionField title="Комиссия Крипто биржи">
          <SharedInputDouble
            view="light"
            v-model="commissionExchangeStoreRequest.crypto_exchange_commission"
          />
        </AdminConfigCommissionField>
      </SharedValidate>

      <SharedValidate
        field="blockchain_transfers_commission"
        :value="commissionExchangeStoreRequest.blockchain_transfers_commission"
        :is-validate="isValidate"
        v-model:field-value="
          commissionExchangeStoreFields.blockchain_transfers_commission
        "
      >
        <AdminConfigCommissionField title="Блокчейн перевод">
          <SharedInputDouble
            view="light"
            v-model="
              commissionExchangeStoreRequest.blockchain_transfers_commission
            "
          />
        </AdminConfigCommissionField>
      </SharedValidate>

      <SharedValidate
        field="volatility"
        :value="commissionExchangeStoreRequest.volatility"
        :is-validate="isValidate"
        v-model:field-value="commissionExchangeStoreFields.volatility"
      >
        <AdminConfigCommissionField title="Волатильность">
          <SharedInputDouble
            view="light"
            v-model="commissionExchangeStoreRequest.volatility"
          />
        </AdminConfigCommissionField>
      </SharedValidate>
    </div>

    <div :class="b('control')">
      <UIButton
        :class="b('save')"
        label="Сохранить"
        view="main"
        :disabled="isSaveDisabled"
        @click="handleSave"
      />
      <UIButton v-if="!isRemoveDisabled"
        :class="b('remove')"
        label="Удалить"
        view="main-outline"
        @click="handleExchangeCommission"
      />
    </div>
  </div>
</template>
