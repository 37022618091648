import { defineComponent } from "vue";

import { PAGE_NAME } from "./attributes";

import {
  WalletMain,
  WalletHistory,
  WalletApplications,
  WalletDeposit,
  WalletWithdrawal,
  WalletRequisitesInfo,
  WalletVerification,
  WalletVerificationRequisites,
} from "@/components/pages/wallet";
import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";
import { DepositWithdrawalHistoryResponse } from "@/shared/repository/modules/deposit-withdrawal/repo";
import { ApplicationHistoryResponse } from "@/shared/repository/modules/application/repo";
import { HistoryFilterRequestFactory } from "@/shared/factory/history";

export type ModalComponent =
  | typeof WalletDeposit
  | typeof WalletWithdrawal
  | typeof WalletRequisitesInfo
  | typeof WalletVerification
  | typeof WalletVerificationRequisites
  | "";

export default defineComponent({
  name: PAGE_NAME,
  components: {
    WalletMain,
    WalletHistory,
    WalletApplications,
    WalletDeposit,
    WalletWithdrawal,
    WalletRequisitesInfo,
    WalletVerification,
  },
  data() {
    return {
      depositWithdrawalHistory: <DepositWithdrawalHistoryResponse[]>[],
      applicationHistory: <ApplicationHistoryResponse[]>[],
      walletRepo: this.$projectServices.walletRepo,
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
      depositWithdrawalRepository:
        this.$projectServices.depositWithdrawalRepository,
      applicationRepository: this.$projectServices.applicationRepository,
    };
  },
  async created(): Promise<void> {
    await this.loadData()
  },
  beforeUnmount(): void {
    this.depositWithdrawalRepo.UPDATE_DEPOSIT({
      id: 0,
      currency_id: 0,
      amount: 0,
      currencyName: "",
    });
  },
  computed: {
    displayedModal(): ModalComponent {
      switch (this.walletRepo.modal.type) {
        case AdminApplicationType.deposit:
          return WalletDeposit;
        case AdminApplicationType.withdrawal:
          return WalletWithdrawal;
        case AdminApplicationType.depositRequisites:
          return WalletRequisitesInfo;
        case AdminApplicationType.verificationDeposit:
        case AdminApplicationType.verificationWithdrawal:
          return WalletVerification;
        case AdminApplicationType.verificationWithdrawalRequisites:
          return WalletVerificationRequisites;
        default:
          return "";
      }
    },

    isModalShow(): boolean {
      return !!this.walletRepo.modal.type;
    },
  },
  methods: {
    async loadData() {
      try {
        const [depositWithdrawalHistory, applicationHistory] = await Promise.all([
          this.depositWithdrawalRepository.history(HistoryFilterRequestFactory()),
          this.applicationRepository.history(HistoryFilterRequestFactory()),
        ]);
  
        this.depositWithdrawalHistory = depositWithdrawalHistory;
        this.applicationHistory = applicationHistory;
      } catch (error) {
        console.log(error);
      }
    }
  }
});
