import { AxiosInstance } from "axios";

import { setCookie, removeCookie } from "@/shared/utils/cookie-helpers";

import { getBearerToken } from "@/shared/utils/token-helpers";

import { CookieName } from "@/shared/constants/enums";

import i18n from "@/plugins/i18n";

import { AuthUrlGenerator } from "./url-generator";
import {
  AuthForgotRequest,
  AuthLoginRequest,
  AuthRegistrationRequest,
  AuthTokenResoponse,
  RetryMailLangRequest
} from "./repo";

export interface AuthProjectRepository {
  csfr(): Promise<void>;
  login(payload: AuthLoginRequest): Promise<void>;
  registration(payload: AuthRegistrationRequest): Promise<void>;
  emailCode(payload: string): Promise<void>;
  codeVerification(payload: string): Promise<void>;
  forgot(payload: AuthForgotRequest): Promise<void>;
  logout(): Promise<void>;
  retryNotification(payload: RetryMailLangRequest): Promise<void>;
  parseToken(): Promise<string>;
}

export class AuthHttpRepo implements AuthProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: AuthUrlGenerator
  ) {}

  async csfr(): Promise<void> {
    await this.axios.get<void>(this.urlGenerator.csfr());
  }

  // async login(payload: AuthLoginRequest): Promise<void> {
  //   await this.axios.post<void>(this.urlGenerator.login(), payload);
  // }

  async login(payload: AuthLoginRequest): Promise<void> {
    const { data } = await this.axios.post<AuthTokenResoponse>(this.urlGenerator.login(), payload);
    setCookie(CookieName.accessToken, data.token)
    this.axios.defaults.headers.common["Authorization"] = getBearerToken()
  }

  async registration(payload: AuthRegistrationRequest): Promise<void> {
    await this.axios.post<string>(this.urlGenerator.registration(), {
      name: `User ${Date.now()}`,
      email: payload.email,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
      lang: payload.lang,
    });
  }

  async emailCode(payload: string): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.emailCode(), {
      email: payload,
      lang: i18n.global.locale
    });
  }

  async codeVerification(payload: string): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.codeVerification(), {
      code: payload,
    });
  }

  async forgot(payload: AuthForgotRequest): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.forgot(), {
      email: payload.email,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
    });
  }

  async logout(): Promise<void> {
    console.log("tut-1")
    await this.axios.post<void>(this.urlGenerator.logout()).finally(() => {
      removeCookie(CookieName.accessToken);
      console.log("tut-2")
    });
  }

  async retryNotification(payload: RetryMailLangRequest): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.retryNotification(), {
      lang: payload.lang,
    });
  }

  async parseToken(): Promise<string> {
    const { data } = await this.axios.get<AuthTokenResoponse>(this.urlGenerator.parseToken());
    return data.token
  }
}