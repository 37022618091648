import axios from "axios";

import { ParserUrlGenerator } from "./url-generator";
import {
  ParserLoginResponse,
  ParserParseRequest,
  ParserResponse,
} from "./repo";

import { ResponseData } from "@/shared/constants/interfaces";
import { downloadFile } from "@/shared/utils/file-helpers";

export interface ParserProjectRepository {
  login(): Promise<void>;
  parse(payload: ParserParseRequest, token: string): Promise<void>;
}

export class ParserHttpRepo implements ParserProjectRepository {
  private token: string = "";
  private axios = axios.create({
    baseURL: process.env.VUE_APP_SERVICE_API_URL,
  });

  constructor(private readonly urlGenerator: ParserUrlGenerator) {}

  async login(): Promise<void> {
    const { data } = await this.axios.post<ResponseData<ParserLoginResponse>>(
      this.urlGenerator.login(),
      {
        login: "tech",
        password: "hJdf1gBdfFg",
      }
    );

    this.token = data.data.Token;
  }

  async parse(payload: ParserParseRequest, token: string): Promise<void> {

    const { data } = await this.axios.post<ParserResponse>(
      this.urlGenerator.parse(),
      payload,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    downloadFile(
      data.docxFile,
      `${payload.fileName}.docx`,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    );
  }
}
