// Svg

import { AdminCurrencyType } from "../repository/modules/admin/currency/enums";

export type SvgAttribute = Record<string, string>;

// Password

export interface ValidationPassword {
  containNumbers: boolean;
  containSpecialCharacters: boolean;
  charactersLong: boolean;
  match: boolean;
  isValidation: boolean;
}

// Code

export const CODE_MAX_LENGTH = 6;

// Notification

export interface Notification {
  title: NotificationTitle | string;
  label: NotificationLabel | string;
  icon: NotificationIcon | string;
}

export enum NotificationTitle {
  password = "Пароль успешно изменен",
}

export enum NotificationLabel {
  password = "Поздравляем, теперь вы можете войти в систему, используя свой новый пароль",
}

export enum NotificationIcon {
  password = "password",
}

// Seconds

export const DEFAILT_SECONDS = 5000;

// Modal

export interface Modal {
  title: ModalTitle | string;
  label: ModalLabel | string;
  icon: ModalIcon | string;
  actionLabel?: ModalActionLabel | string;
  callback: () => void;
}

export enum ModalTitle {
  application = "Заявка отправлена",
  requisites = "Подтверждение реквизитов",
  confirmTransfer = "Подтверждение перевода",
  adminStatusUdate = "Статус пользователя изменен",
  adminAccountBlocked = "Аккаунт заблокирован",
  adminBalanceUpdate = "Баланс изменен",
  adminStatusUserUpdateSuccess = "Статус успешно изменен",
  adminStatusUserUpdateError = "Не удалось изменить статус",
  exchange = "Комиссия успешно сохранена",
  exchangeRemove = "Комиссия успешно удалена",
  bankRemove = "Удаление реквизитов",
}

export enum ModalLabel {
  default = "",
  application = `<span>Ваша заявка на прохождение верификации отправлена на рассмотрение. Ориентировочное время ожидания 24 часа</span>`,
  requisites = `<span>Для продолжения, подтвердите реквизиты через письмо на почтовом ящике</span>`,
  confirmTransfer = `<span>Вы подтвердили перевод по указанным реквизитам. Проверяем.</span>`,
  adminStatusUpdate = `<span>С “На рассмотрении” на “Отклонено”. <br /> Комментарий отправлен на почту email@email.com</span>`,
  adminAccountBlocked = `<span>Ваш аккаунт заблокирован и будет удален через 6:59:59:59</span>`,
  adminBalanceUpdate = "Комментарий отправлен на почту email@email.com",
  applicationDeposit = `<span>Ваша заявка на ввод средств отправлена на рассмотрение. Ориентировочное время ожидания 24 часа</span>`,
  applicationWithdrawal = `<span>Ваша заявка на вывод средств отправлена на рассмотрение. Ориентировочное время ожидания 24 часа</span>`,
  bankRemove = `<span>Вы действительно хотите удалить реквизиты?</span>`,
  applicationExchange = `<span>Ваша заявка на обмен отправлена на рассмотрение. <br /> Ориентировочное время ожидания 24 часа.</span>`,
}

export enum ModalIcon {
  application = "application",
  requisites = "requisites",
  confirmTransfer = "confirmTransfer",
  adminStatusUdate = "adminStatusUpdate",
  adminAccountBlocked = "adminAccountBlocked",
  adminBalanceUpdate = "adminBalanceUpdate",
}

export enum ModalActionLabel {
  ok = "ok",
  adminAccountBlocked = "Отменить удаление",
  yes = "Да",
}

// Select

export enum SharedSelectOptionLabel {
  RF = "Российская федерация",
  russia = "Россия",
  moscow = "Москва",
}

export enum SharedSelectOptionValue {
  RUSSIA = "Россия",
  RF = "РФ",
  russia = "Russia",
  moscow = "Москва",
}

// Login

export enum LoginMessage {
  already = "Already authenticated",
}

// Multi Select

export interface SharedMultiSelectOption {
  id: number;
  label: string;
  value: string | number;
  icon?: string;
  isActive: boolean;
}

// Crypto Select

export interface SharedCryptoSelectOption {
  id: number;
  label: string;
  currency: {
    fiatIcon: string;
    cryptoIcon: string;
    name: string;
    type: AdminCurrencyType;
  };
  maxAmount: number;
  isActive?: boolean;
}

// History

export interface HistoryFilter {
  currency: SharedMultiSelectOption[];
  volume: {
    from: string;
    to: string;
  };
  date: string[];
  status: SharedMultiSelectOption[];
  type: SharedMultiSelectOption[];
}

// Application

export interface ApplicationRequest {
  from: SharedCryptoSelectOption;
  to: SharedCryptoSelectOption;
  amount: string;
  crypto: {
    coin: string;
    network: string;
    address: string;
  };
}

export const CountryCurrencies = {
  af: { name: "Afghan Afghani", currency: "afn" },
  al: { name: "Albanian Lek", currency: "all" },
  dz: { name: "Algerian Dinar", currency: "dzd" },
  ad: { name: "Euro", currency: "eur" },
  ao: { name: "Angolan Kwanza", currency: "aoa" },
  ag: { name: "East Caribbean Dollar", currency: "xcd" },
  ar: { name: "Argentine Peso", currency: "ars" },
  am: { name: "Armenian Dram", currency: "amd" },
  au: { name: "Australian Dollar", currency: "aud" },
  at: { name: "Euro", currency: "eur" },
  az: { name: "Azerbaijani Manat", currency: "azn" },
  bs: { name: "Bahamian Dollar", currency: "bsd" },
  bh: { name: "Bahraini Dinar", currency: "bhd" },
  bd: { name: "Bangladeshi Taka", currency: "bdt" },
  bb: { name: "Barbadian Dollar", currency: "bbd" },
  by: { name: "Belarusian Ruble", currency: "byn" },
  be: { name: "Euro", currency: "eur" },
  bz: { name: "Belize Dollar", currency: "bzd" },
  bj: { name: "West African CFA Franc", currency: "xof" },
  bt: { name: "Bhutanese Ngultrum", currency: "btn" },
  bo: { name: "Bolivian Boliviano", currency: "bob" },
  ba: { name: "Bosnia and Herzegovina Convertible Mark", currency: "bam" },
  bw: { name: "Botswana Pula", currency: "bwp" },
  br: { name: "Brazilian Real", currency: "brl" },
  bn: { name: "Brunei Dollar", currency: "bnd" },
  bg: { name: "Bulgarian Lev", currency: "bgn" },
  bf: { name: "West African CFA Franc", currency: "xof" },
  bi: { name: "Burundian Franc", currency: "bif" },
  cv: { name: "Cape Verdean Escudo", currency: "cve" },
  kh: { name: "Cambodian Riel", currency: "khr" },
  cm: { name: "Central African CFA Franc", currency: "xaf" },
  ca: { name: "Canadian Dollar", currency: "cad" },
  cf: { name: "Central African CFA Franc", currency: "xaf" },
  td: { name: "Central African CFA Franc", currency: "xaf" },
  cl: { name: "Chilean Peso", currency: "clp" },
  cn: { name: "Chinese Yuan", currency: "cny" },
  co: { name: "Colombian Peso", currency: "cop" },
  km: { name: "Comorian Franc", currency: "kmf" },
  cd: { name: "Congolese Franc", currency: "cdf" },
  cg: { name: "Central African CFA Franc", currency: "xaf" },
  cr: { name: "Costa Rican Colón", currency: "crc" },
  ci: { name: "West African CFA Franc", currency: "xof" },
  hr: { name: "Euro", currency: "eur" },
  cu: { name: "Cuban Peso", currency: "cup" },
  cy: { name: "Euro", currency: "eur" },
  cz: { name: "Czech Koruna", currency: "czk" },
  dk: { name: "Danish Krone", currency: "dkk" },
  dj: { name: "Djiboutian Franc", currency: "djf" },
  dm: { name: "East Caribbean Dollar", currency: "xcd" },
  do: { name: "Dominican Peso", currency: "dop" },
  tl: { name: "United States Dollar", currency: "usd" },
  ec: { name: "United States Dollar", currency: "usd" },
  eg: { name: "Egyptian Pound", currency: "egp" },
  sv: { name: "United States Dollar", currency: "usd" },
  gq: { name: "Central African CFA Franc", currency: "xaf" },
  er: { name: "Eritrean Nakfa", currency: "ern" },
  ee: { name: "Euro", currency: "eur" },
  sz: { name: "Eswatini Lilangeni", currency: "szl" },
  et: { name: "Ethiopian Birr", currency: "etb" },
  fj: { name: "Fijian Dollar", currency: "fjd" },
  fi: { name: "Euro", currency: "eur" },
  fr: { name: "Euro", currency: "eur" },
  ga: { name: "Central African CFA Franc", currency: "xaf" },
  gm: { name: "Gambian Dalasi", currency: "gmd" },
  ge: { name: "Georgian Lari", currency: "gel" },
  de: { name: "Euro", currency: "eur" },
  gh: { name: "Ghanaian Cedi", currency: "ghs" },
  gr: { name: "Euro", currency: "eur" },
  gd: { name: "East Caribbean Dollar", currency: "xcd" },
  gt: { name: "Guatemalan Quetzal", currency: "gtq" },
  gn: { name: "Guinean Franc", currency: "gnf" },
  gw: { name: "West African CFA Franc", currency: "xof" },
  gy: { name: "Guyanese Dollar", currency: "gyd" },
  ht: { name: "Haitian Gourde", currency: "htg" },
  hn: { name: "Honduran Lempira", currency: "hnl" },
  hu: { name: "Hungarian Forint", currency: "huf" },
  is: { name: "Icelandic Króna", currency: "isk" },
  in: { name: "Indian Rupee", currency: "inr" },
  id: { name: "Indonesian Rupiah", currency: "idr" },
  ir: { name: "Iranian Rial", currency: "irr" },
  iq: { name: "Iraqi Dinar", currency: "iqd" },
  ie: { name: "Euro", currency: "eur" },
  il: { name: "Israeli New Shekel", currency: "ils" },
  it: { name: "Euro", currency: "eur" },
  jm: { name: "Jamaican Dollar", currency: "jmd" },
  jp: { name: "Japanese Yen", currency: "jpy" },
  jo: { name: "Jordanian Dinar", currency: "jod" },
  kz: { name: "Kazakhstani Tenge", currency: "kzt" },
  ke: { name: "Kenyan Shilling", currency: "kes" },
  ki: { name: "Australian Dollar", currency: "aud" },
  kp: { name: "North Korean Won", currency: "kpw" },
  kr: { name: "South Korean Won", currency: "krw" },
  xk: { name: "Euro", currency: "eur" },
  kw: { name: "Kuwaiti Dinar", currency: "kwd" },
  kg: { name: "Kyrgyzstani Som", currency: "kgs" },
  la: { name: "Lao Kip", currency: "lak" },
  lv: { name: "Euro", currency: "eur" },
  lb: { name: "Lebanese Pound", currency: "lbp" },
  ls: { name: "Lesotho Loti", currency: "lsl" },
  lr: { name: "Liberian Dollar", currency: "lrd" },
  ly: { name: "Libyan Dinar", currency: "lyd" },
  li: { name: "Swiss Franc", currency: "chf" },
  lt: { name: "Euro", currency: "eur" },
  lu: { name: "Euro", currency: "eur" },
  mg: { name: "Malagasy Ariary", currency: "mga" },
  mw: { name: "Malawian Kwacha", currency: "mwk" },
  my: { name: "Malaysian Ringgit", currency: "myr" },
  mv: { name: "Maldivian Rufiyaa", currency: "mvr" },
  ml: { name: "West African CFA Franc", currency: "xof" },
  mt: { name: "Euro", currency: "eur" },
  mh: { name: "United States Dollar", currency: "usd" },
  mr: { name: "Mauritanian Ouguiya", currency: "mru" },
  mu: { name: "Mauritian Rupee", currency: "mur" },
  mx: { name: "Mexican Peso", currency: "mxn" },
  fm: { name: "United States Dollar", currency: "usd" },
  md: { name: "Moldovan Leu", currency: "mdl" },
  mc: { name: "Euro", currency: "eur" },
  mn: { name: "Mongolian Tögrög", currency: "mnt" },
  me: { name: "Euro", currency: "eur" },
  ma: { name: "Moroccan Dirham", currency: "mad" },
  mz: { name: "Mozambican Metical", currency: "mzn" },
  mm: { name: "Burmese Kyat", currency: "mmk" },
  na: { name: "Namibian Dollar", currency: "nad" },
  nr: { name: "Australian Dollar", currency: "aud" },
  np: { name: "Nepalese Rupee", currency: "npr" },
  nl: { name: "Euro", currency: "eur" },
  nz: { name: "New Zealand Dollar", currency: "nzd" },
  ni: { name: "Nicaraguan Córdoba", currency: "nio" },
  ne: { name: "West African CFA Franc", currency: "xof" },
  ng: { name: "Nigerian Naira", currency: "ngn" },
  mk: { name: "Macedonian Denar", currency: "mkd" },
  no: { name: "Norwegian Krone", currency: "nok" },
  om: { name: "Omani Rial", currency: "omr" },
  pk: { name: "Pakistani Rupee", currency: "pkr" },
  pw: { name: "United States Dollar", currency: "usd" },
  ps: { name: "Israeli New Shekel", currency: "ils" },
  pa: { name: "Panamanian Balboa", currency: "pab" },
  pg: { name: "Papua New Guinean Kina", currency: "pgk" },
  py: { name: "Paraguayan Guaraní", currency: "pyg" },
  pe: { name: "Peruvian Sol", currency: "pen" },
  ph: { name: "Philippine Peso", currency: "php" },
  pl: { name: "Polish Złoty", currency: "pln" },
  pt: { name: "Euro", currency: "eur" },
  qa: { name: "Qatari Riyal", currency: "qar" },
  ro: { name: "Romanian Leu", currency: "ron" },
  ru: { name: "Russian Ruble", currency: "rub" },
  rw: { name: "Rwandan Franc", currency: "rwf" },
  kn: { name: "East Caribbean Dollar", currency: "xcd" },
  lc: { name: "East Caribbean Dollar", currency: "xcd" },
  vc: { name: "East Caribbean Dollar", currency: "xcd" },
  ws: { name: "Samoan Tālā", currency: "wst" },
  sm: { name: "Euro", currency: "eur" },
  st: { name: "São Tomé and Príncipe Dobra", currency: "stn" },
  sa: { name: "Saudi Riyal", currency: "sar" },
  sn: { name: "West African CFA Franc", currency: "xof" },
  rs: { name: "Serbian Dinar", currency: "rsd" },
  sc: { name: "Seychellois Rupee", currency: "scr" },
  sl: { name: "Sierra Leonean Leone", currency: "sll" },
  sg: { name: "Singapore Dollar", currency: "sgd" },
  sk: { name: "Euro", currency: "eur" },
  si: { name: "Euro", currency: "eur" },
  sb: { name: "Solomon Islands Dollar", currency: "sbd" },
  so: { name: "Somali Shilling", currency: "sos" },
  za: { name: "South African Rand", currency: "zar" },
  ss: { name: "South Sudanese Pound", currency: "ssp" },
  es: { name: "Euro", currency: "eur" },
  lk: { name: "Sri Lankan Rupee", currency: "lkr" },
  sd: { name: "Sudanese Pound", currency: "sdg" },
  sr: { name: "Surinamese Dollar", currency: "srd" },
  se: { name: "Swedish Krona", currency: "sek" },
  ch: { name: "Swiss Franc", currency: "chf" },
  sy: { name: "Syrian Pound", currency: "syp" },
  tw: { name: "New Taiwan Dollar", currency: "twd" },
  tj: { name: "Tajikistani Somoni", currency: "tjs" },
  tz: { name: "Tanzanian Shilling", currency: "tzs" },
  th: { name: "Thai Baht", currency: "thb" },
  tg: { name: "West African CFA Franc", currency: "xof" },
  to: { name: "Tongan Paʻanga", currency: "top" },
  tt: { name: "Trinidad and Tobago Dollar", currency: "ttd" },
  tn: { name: "Tunisian Dinar", currency: "tnd" },
  tr: { name: "Turkish Lira", currency: "try" },
  tm: { name: "Turkmenistan Manat", currency: "tmt" },
  tv: { name: "Australian Dollar", currency: "aud" },
  ug: { name: "Ugandan Shilling", currency: "ugx" },
  ua: { name: "Ukrainian Hryvnia", currency: "uah" },
  ae: { name: "United Arab Emirates Dirham", currency: "aed" },
  gb: { name: "British Pound Sterling", currency: "gbp" },
  us: { name: "United States Dollar", currency: "usd" },
  uy: { name: "Uruguayan Peso", currency: "uyu" },
  uz: { name: "Uzbekistani Som", currency: "uzs" },
  vu: { name: "Vanuatu Vatu", currency: "vuv" },
  va: { name: "Euro", currency: "eur" },
  ve: { name: "Venezuelan Bolívar Soberano", currency: "ves" },
  vn: { name: "Vietnamese đồng", currency: "vnd" },
  ye: { name: "Yemeni Rial", currency: "yer" },
  zm: { name: "Zambian Kwacha", currency: "zmw" },
  zw: { name: "Zimbabwean Dollar", currency: "zwl" },
};

// Coin

export enum Coins {
  "xmy" = "xmy",
  "abt" = "abt",
  "vtc" = "vtc",
  "mnz" = "mnz",
  "rcn" = "rcn",
  "usdc" = "usdc",
  "yfi" = "yfi",
  "icn" = "icn",
  "usdt" = "usdt",
  "theta" = "theta",
  "lrc" = "lrc",
  "pungo" = "pungo",
  "blcn" = "blcn",
  "usd" = "usd",
  "rub" = "rub",
  "ndz" = "ndz",
  "icx" = "icx",
  "rvn" = "rvn",
  "wicc" = "wicc",
  "rap" = "rap",
  "audr" = "audr",
  "cloak" = "cloak",
  "xtz" = "xtz",
  "oxt" = "oxt",
  "spacehbit" = "spacehbit",
  "xmo" = "xmo",
  "ant" = "ant",
  "payx" = "payx",
  "jpy" = "jpy",
  "cmm" = "cmm",
  "atm" = "atm",
  "sin" = "sin",
  "xvg" = "xvg",
  "dew" = "dew",
  "dent" = "dent",
  "tbx" = "tbx",
  "gvt" = "gvt",
  "eng" = "eng",
  "kmd" = "kmd",
  "ngc" = "ngc",
  "safemoon" = "safemoon",
  "aeur" = "aeur",
  "strat" = "strat",
  "ion" = "ion",
  "ink" = "ink",
  "gbp" = "gbp",
  "1inch" = "1inch",
  "smart" = "smart",
  "eth" = "eth",
  "eca" = "eca",
  "rlc" = "rlc",
  "mcap" = "mcap",
  "shift" = "shift",
  "bcpt" = "bcpt",
  "mnx" = "mnx",
  "tau" = "tau",
  "fsn" = "fsn",
  "xcp" = "xcp",
  "xbc" = "xbc",
  "xbp" = "xbp",
  "safe" = "safe",
  "btdx" = "btdx",
  "music" = "music",
  "burst" = "burst",
  "nuls" = "nuls",
  "cnd" = "cnd",
  "wabi" = "wabi",
  "qsp" = "qsp",
  "hsr" = "hsr",
  "eqli" = "eqli",
  "gzr" = "gzr",
  "aeon" = "aeon",
  "mona" = "mona",
  "plr" = "plr",
  "wgr" = "wgr",
  "dock" = "dock",
  "aury" = "aury",
  "bpt" = "bpt",
  "neo" = "neo",
  "repv2" = "repv2",
  "eop" = "eop",
  "gto" = "gto",
  "pink" = "pink",
  "wings" = "wings",
  "iost" = "iost",
  "neu" = "neu",
  "poe" = "poe",
  "bnty" = "bnty",
  "pay" = "pay",
  "gbx" = "gbx",
  "ilk" = "ilk",
  "iop" = "iop",
  "moac" = "moac",
  "pax" = "pax",
  "aion" = "aion",
  "eur" = "eur",
  "trig" = "trig",
  "data" = "data",
  "emc" = "emc",
  "gup" = "gup",
  "mln" = "mln",
  "aave" = "aave",
  "html" = "html",
  "ser" = "ser",
  "xuc" = "xuc",
  "zcl" = "zcl",
  "sky" = "sky",
  "mth" = "mth",
  "xmr" = "xmr",
  "nlc2" = "nlc2",
  "amp" = "amp",
  "mzc" = "mzc",
  "paxg" = "paxg",
  "oax" = "oax",
  "xby" = "xby",
  "ethos" = "ethos",
  "mtl" = "mtl",
  "sib" = "sib",
  "coqui" = "coqui",
  "dgd" = "dgd",
  "qash" = "qash",
  "gxs" = "gxs",
  "klown" = "klown",
  "gmr" = "gmr",
  "ryo" = "ryo",
  "exp" = "exp",
  "poa" = "poa",
  "ankr" = "ankr",
  "ncash" = "ncash",
  "arnx" = "arnx",
  "eon" = "eon",
  "wpr" = "wpr",
  "enj" = "enj",
  "atom" = "atom",
  "drop" = "drop",
  "fil" = "fil",
  "amb" = "amb",
  "iq" = "iq",
  "bcio" = "bcio",
  "ont" = "ont",
  "equa" = "equa",
  "block" = "block",
  "tnc" = "tnc",
  "cny" = "cny",
  "oxy" = "oxy",
  "tnt" = "tnt",
  "poly" = "poly",
  "chsb" = "chsb",
  "fldc" = "fldc",
  "ctxc" = "ctxc",
  "bsd" = "bsd",
  "pot" = "pot",
  "lsk" = "lsk",
  "gas" = "gas",
  "ins" = "ins",
  "nkn" = "nkn",
  "etp" = "etp",
  "evx" = "evx",
  "bdl" = "bdl",
  "ela" = "ela",
  "2give" = "2give",
  "hpb" = "hpb",
  "mod" = "mod",
  "max" = "max",
  "cmt" = "cmt",
  "cnx" = "cnx",
  "tnb" = "tnb",
  "ht" = "ht",
  "powr" = "powr",
  "fjc" = "fjc",
  "btc" = "btc",
}

export const PAGE_SIZE = 10;
