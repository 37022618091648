import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { DepositWithdrawalHistoryResponse } from "@/shared/repository/modules/deposit-withdrawal/repo";
import { SvgAttribute } from "@/shared/constants/constants";
import { formatFiatCurrency } from "@/shared/utils/amount-helpers";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    row: {
      type: <PropType<DepositWithdrawalHistoryResponse>>Object,
      required: true,
    },
  },
  computed: {
    displayedSvg(): SvgAttribute {
      return SVG;
    },

    displayedAmount(): string {
      return formatFiatCurrency(this.row.amount - this.row.commissions);
    },
  },
});
