import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput, UIButton } from "@/components/ui";
import { SharedSelect } from "@/components/shared";

import { DepositWithdrawalStoreRequestFactory } from "@/shared/repository/modules/deposit-withdrawal/factory";
import { SharedSelectOption } from "@/components/shared/select/model";
import { SelectOptionFactory } from "@/shared/factory/select";
import { AdminCurrencyShowResponse } from "@/shared/repository/modules/admin/currency/repo";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";
// import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";
import { WalletModalFactory } from "@/shared/repository/modules/wallet/factory";
import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";
// import { ApplicationFilterRequestFactory } from "@/shared/repository/modules/application/factory";
import { formatFiatCurrency } from "@/shared/utils/amount-helpers";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    UIButton,
    SharedSelect,
  },
  data() {
    return {
      isLoading: false,
      currency: <AdminCurrencyShowResponse[]>[],
      depositWithdrawalStore: DepositWithdrawalStoreRequestFactory(
        {
          user_id: this.$projectServices.userRepo.userInfo.id,
        },
        true
      ),
      userRepo: this.$projectServices.userRepo,
      modalRepo: this.$projectServices.modalRepo,
      walletRepo: this.$projectServices.walletRepo,
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
      walletRepository: this.$projectServices.walletRepository,
      depositWithdrawalRepository:
        this.$projectServices.depositWithdrawalRepository,
      requisitesRepository: this.$projectServices.requisitesRepository,
      adminCommissionRepository:
        this.$projectServices.adminCommissionRepository,
      commissionPercent: <number>(0),
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      const commissionRequest = {
        user_id: this.userRepo.userInfo.id,
        currency_type: AdminCurrencyType.fiat,
        app_type: this.walletRepo.modal.type == AdminApplicationType.deposit ? AdminApplicationType.deposit : AdminApplicationType.withdrawal
      }

      const [currency, requisites, commission] = await Promise.all([
        this.walletRepository.currency(),
        this.adminCommissionRepository.requisitesList(),
        this.depositWithdrawalRepository.commission(commissionRequest)
      ]);

      this.commissionPercent = commission.commission

      this.currency = currency.filter(
        (item) =>
          item.type === AdminCurrencyType.fiat &&
          !item.frozen &&
          requisites.find((requisite) => requisite.currency.id === item.id)
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedCurrencyOptions(): SharedSelectOption[] {
      return this.currency.map((currency) =>
        SelectOptionFactory(
          {
            id: currency.id,
            label: currency.name.toUpperCase(),
            value: currency.id,
            icon: currency.country,
          },
          this.depositWithdrawalStore.currency_id
        )
      );
    },

    displayedCurrencyIcon(): string {
      return (
        this.currency.find(
          (currency) => currency.id === this.depositWithdrawalStore.currency_id
        )?.country ?? ""
      );
    },

    displayedCurrencyName(): string {
      return (
        this.currency.find(
          (currency) => currency.id === this.depositWithdrawalStore.currency_id
        )?.name.toUpperCase() ?? ""
      );
    },

    isDisabled(): boolean {
      const { amount, new_requisite } = this.depositWithdrawalStore;
      const {
        account_number,
        correspondent_account,
        bank_identification_code,
        bank_name,
        inn,
        registration_reason_code,
      } = new_requisite!;

      return (
        !account_number ||
        !correspondent_account ||
        !bank_identification_code ||
        !bank_name ||
        !inn ||
        !registration_reason_code ||
        !amount ||
        this.isLoading
      );
    },
    isCommissionsShow(): boolean {
      if (this.depositWithdrawalStore.currency_id && this.depositWithdrawalStore.amount && this.depositWithdrawalStore.amount > 0) {
        return true
      } else {
        return false
      }
    },
    displayedCommission(): string {
      return formatFiatCurrency(0.01 * this.depositWithdrawalStore.amount * this.commissionPercent.valueOf())
    },
    displayedAmount(): string {
      return formatFiatCurrency(this.depositWithdrawalStore.amount - (0.01 * this.depositWithdrawalStore.amount * this.commissionPercent.valueOf()))
    },
  },
  methods: {
    async handleCreate(): Promise<void> {
      try {
        this.isLoading = true;

        await this.requisitesRepository.bankDeposit(
          this.depositWithdrawalStore.new_requisite!,
          this.userRepo.userInfo.id,
          this.depositWithdrawalStore.currency_id,
          this.depositWithdrawalStore.amount,
          'deposit',
          'fiat',
          null, null, null
        );

        this.depositWithdrawalRepo.UPDATE_APPLICATION(
          this.depositWithdrawalStore
        );

        this.walletRepo.UPDATE_MODAL(
          WalletModalFactory({
            type:
              this.walletRepo.modal.type === AdminApplicationType.deposit
                ? AdminApplicationType.verificationDeposit
                : AdminApplicationType.verificationWithdrawalRequisites,
          })
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
