<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <div :class="b('scores')">
        <div :class="b('score')">
          <h4 :class="b('title')">
            {{ $t("Со счета") }}
          </h4>

          <SharedCryptoSelect
            :placeholder="$t('Выберите из пресетов')"
            :options="displayedFromOptions"
            :value="displayedApplicationStoreRequest.currency_id_from"
            @update:value="handleCurrencyIdFrom"
            @create="handleCryptoCreate"
          />

          <SharedSelect
            v-if="isRequisitesFromShow"
            :class="b('requisites')"
            view="light"
            :placeholder="$t('Выберите реквизиты')"
            :options="displayedRequisitesFromOptions"
            v-model:value="
              displayedApplicationStoreRequest.user_requisite_id_from
            "
          />
        </div>

        <span :class="b('scores-icon')" v-html="displayedArrowIcon"></span>

        <div :class="b('score')">
          <div :class="b('score')">
            <h4 :class="b('title')">
              {{ $t("На счет") }}
            </h4>

            <SharedCryptoSelect
              :placeholder="$t('Выберите из пресетов / создайте новый')"
              :options="displayedToOptions"
              :value="displayedApplicationStoreRequest.currency_id_to"
              @update:value="handleCurrencyIdTo"
              @create="handleCryptoCreate"
            />

            <SharedSelect
              v-if="isRequisitesToShow"
              :class="b('requisites')"
              view="light"
              :placeholder="$t('Выберите реквизиты')"
              :options="displayedRequisitesToOptions"
              v-model:value="
                displayedApplicationStoreRequest.user_requisite_id_to
              "
            />
          </div>

          <div v-if="isCommissionsShow" :class="b('message', { amount: true })">
            <div :class="b('message-item')">
              <p :class="b('message-label')">
                {{ $t("Текущий курс") }}
              </p>

              <p :class="b('message-label')">
                ~ {{ displayedCourse }}
                {{ displayedCurrencyTo?.name.toUpperCase() }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div :class="b('amount')">
        <div :class="b('amount-group')">
          <h4 :class="b('title')">
            {{ $t("Сумма") }}
          </h4>

          <UIButton label="МАКС" view="max" @click="handleMax" />
        </div>

        <div>
          <SharedCurrency
            :label="displayedCurrencyName.toUpperCase()"
            :value="displayedApplicationStoreRequest.amount"
            :max-value="displayedApplicationStoreRequest.max_amount"
            :is-number="true"
            :is-max-value="true"
            :is-error="
              displayedApplicationStoreRequest.currency_type_from === 'fiat' &&
              displayedApplicationStoreRequest.currency_type_to === 'fiat' &&
              displayedApplicationStoreRequest.amount >
                displayedApplicationStoreRequest.max_amount
            "
            @update:value="handleAmount"
          />

          <p
            :class="b('message-error')"
            v-if="
              displayedApplicationStoreRequest.currency_type_from === 'fiat' &&
              displayedApplicationStoreRequest.currency_type_to === 'fiat' &&
              displayedApplicationStoreRequest.amount >
                displayedApplicationStoreRequest.max_amount
            "
          >
            {{ $t('Не хватает средств') }}
          </p>
        </div>

        <div v-if="isCommissionsShow" :class="b('message', { amount: true })">
          <div :class="b('message-item')">
            <p :class="b('message-label')">
              {{ $t("Комиссия") }}
            </p>

            <p :class="b('message-label')">
              ~ {{ displayedCommission }}
              {{ displayedCurrencyFrom?.currency?.name.toUpperCase() }}
            </p>
          </div>

          <div :class="b('message-item')">
            <p :class="b('message-label')">
              {{ $t("Вы получите") }}
            </p>

            <p :class="b('message-label')">
              ~
              {{ displayedAmount }}
              {{
                displayedCurrencyTo?.currency?.name.toUpperCase() ||
                displayedCurrencyTo?.name.toUpperCase()
              }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <UIButton
      label="Далее"
      view="main"
      :disabled="isNextDisabled"
      @click="handleNext"
    />
  </div>
</template>
