import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { AdminConfigCommissionField } from "../field";
import { SharedValidate, SharedInputDouble } from "@/components/shared";
import { UIButton } from "@/components/ui";

import { AdminCommissionExchangeType } from "@/shared/repository/modules/admin/commission/enums";
import {
  AdminCommissionExchangeListResponseFactory,
  AdminCommissionExchangeStoreFieldsFactory,
  AdminCommissionExchangeStoreRequestFactory,
  AdminCommissionExchangeUpdateRequestFactory,
} from "@/shared/repository/modules/admin/commission/factory";
import {
  ModalActionLabel,
  ModalIcon,
  ModalLabel,
  ModalTitle,
} from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminConfigCommissionField,
    SharedValidate,
    SharedInputDouble,
    UIButton,
  },
  props: {
    type: {
      type: <PropType<AdminCommissionExchangeType>>String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  emits: {
    "update:userId": null,
  },
  data() {
    return {
      isValidate: false,
      isLoading: false,
      commissionExchangeStoreRequest:
        AdminCommissionExchangeListResponseFactory(),
      commissionExchangeStoreFields:
        AdminCommissionExchangeStoreFieldsFactory(),
      adminCommissionRepository:
        this.$projectServices.adminCommissionRepository,
      usersRepo: this.$projectServices.usersRepo,
      modalRepo: this.$projectServices.modalRepo,
    };
  },
  async created(): Promise<void> {
    await this.handleExchangeList();
  },
  watch: {
    async type(): Promise<void> {
      this.commissionExchangeStoreRequest =
        AdminCommissionExchangeListResponseFactory();
      this.$emit("update:userId", 0);

      if (this.type === AdminCommissionExchangeType.user && this.userId) {
        await this.handleExchangeList();
      } else if (this.type === AdminCommissionExchangeType.all) {
        await this.handleExchangeList();
      }
    },

    async userId(): Promise<void> {
      if (this.type === AdminCommissionExchangeType.user && this.userId) {
        await this.handleExchangeList();
      }
    },
  },
  computed: {
    isDisabled(): boolean {
      return Object.values(this.commissionExchangeStoreFields).some(
        (field) => !field
      );
    },

    isSaveDisabled(): boolean {
      return this.type === AdminCommissionExchangeType.all
        ? this.isLoading
        : this.isLoading || !this.userId;
    },

    isRemoveDisabled(): boolean {
      return this.type != AdminCommissionExchangeType.user || (this.isLoading || !this.userId || !this.commissionExchangeStoreRequest.id)
    }
  },
  methods: {
    async handleExchangeList(): Promise<void> {
      try {
        this.isLoading = true;

        this.commissionExchangeStoreRequest =
          await this.adminCommissionRepository.exchangeList(
            this.type,
            this.type === AdminCommissionExchangeType.user ? this.userId : 0
          );
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async handleSave(): Promise<void> {
      try {
        this.isLoading = true;

        if (this.isDisabled) {
          this.isValidate = true;

          return;
        }

        if (this.commissionExchangeStoreRequest.id) {
          await this.adminCommissionRepository.exchangeUpdate(
            this.commissionExchangeStoreRequest.id,
            AdminCommissionExchangeUpdateRequestFactory(
              this.commissionExchangeStoreRequest
            )
          );
        } else {
          await this.adminCommissionRepository.exchangeStore(
            this.type,
            AdminCommissionExchangeStoreRequestFactory({
              ...this.commissionExchangeStoreRequest,
              type: this.type,
              user_id:
                this.type === AdminCommissionExchangeType.user
                  ? this.userId
                  : 0,
            })
          );
        }

        this.modalRepo.UPDATE_MODAL({
          title: ModalTitle.exchange,
          label: ModalLabel.default,
          icon: ModalIcon.adminBalanceUpdate,
          actionLabel: ModalActionLabel.ok,
          callback: () => {
            this.handleExchangeList()
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async handleExchangeCommission() {
      if (this.commissionExchangeStoreRequest.id) {
        try {
          this.isLoading = true;

          await this.adminCommissionRepository.exchangeDelete(this.commissionExchangeStoreRequest.id)

          this.modalRepo.UPDATE_MODAL({
            title: ModalTitle.exchangeRemove,
            label: ModalLabel.default,
            icon: ModalIcon.adminBalanceUpdate,
            actionLabel: ModalActionLabel.ok,
            callback: () => {
              this.handleExchangeList()
            },
          });
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
});
