import { defineComponent, PropType } from "vue";
import { v4 } from "uuid";

import { COMPONENT_NAME, SVG } from "./attributes";

import {
  ModalActionLabel,
  ModalIcon,
  ModalLabel,
  ModalTitle,
  SvgAttribute,
} from "@/shared/constants/constants";
import { AdminRequisitesBankShowResponse } from "@/shared/repository/modules/admin/requisites/repo";
import { AdminRequisitesBankShowResponseFactory } from "@/shared/repository/modules/admin/requisites/factory";

const CORRESPONDENT_BANK_MAX_LENGTH = 25;
const BANKS_MAX_LENGTH = 10;

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    bankId: {
      type: [String, Number],
      default: "",
    },
    banks: {
      type: <PropType<AdminRequisitesBankShowResponse[]>>Array,
      default: () => [],
    },
  },
  emits: {
    "update:bankId": null,
    "update:banks": null,
  },
  data() {
    return {
      modalRepo: this.$projectServices.modalRepo,
    };
  },
  created() {
    if (this.banks.length) {
      this.handleBank(this.banks[0]);
    } else {
      this.handleBankCreate();
    }
  },
  computed: {
    displayedSvg(): SvgAttribute {
      return SVG;
    },

    isCreateShow(): boolean {
      return this.banks.length < BANKS_MAX_LENGTH;
    },
  },
  methods: {
    handleBankLabel(correspondentBank: string): string {
      return correspondentBank.length > CORRESPONDENT_BANK_MAX_LENGTH
        ? `${correspondentBank.substring(0, CORRESPONDENT_BANK_MAX_LENGTH)}...`
        : correspondentBank;
    },

    handleBank(bank: AdminRequisitesBankShowResponse): void {
      this.$emit("update:bankId", bank.id);
    },

    handleBankCreate(): void {
      const bank = AdminRequisitesBankShowResponseFactory({
        id: v4(),
        correspondent_bank: `Банк ${this.banks.length + 1}`,
      });

      this.$emit("update:banks", [...this.banks, bank]);
      this.$emit("update:bankId", bank.id);
    },

    handleBankRemove(id: number): void {
      this.modalRepo.UPDATE_MODAL({
        title: ModalTitle.bankRemove,
        label: ModalLabel.bankRemove,
        icon: ModalIcon.adminAccountBlocked,
        actionLabel: ModalActionLabel.yes,
        callback: () => {
          const banks = this.banks.filter(
            (bankItem) => bankItem.id !== id
          );

          if (this.banks.length === 1) {
            const bank = AdminRequisitesBankShowResponseFactory({
              id: v4(),
              correspondent_bank: `Банк ${banks.length + 1}`,
            });

            this.$emit("update:banks", [bank, ...banks]);
            this.$emit("update:bankId", bank.id);
          } else {
            this.$emit("update:banks", banks);
            this.$emit("update:bankId", banks[0].id);
          }
        },
      });
    },
  },
});
