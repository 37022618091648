import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedWindow,
  SharedSelect,
  SharedInputCurrency,
  SharedEstimate,
} from "@/components/shared";
import { UIInput, UIButton, UITextArea } from "@/components/ui";
import { SharedSelectOption } from "@/components/shared/select/model";
import { SelectOptionFactory } from "@/shared/factory/select";
import {
  AdminDepositWithdrawalShowResponseFactory,
  AdminDepositWithdrawalUpdateRequestFactory,
} from "@/shared/repository/modules/admin/deposit-withdrawal/factory";
import { AdminDepositWithdrawalListResponse } from "@/shared/repository/modules/admin/deposit-withdrawal/repo";
import { AdminDepositWithdrawalStatus } from "@/shared/repository/modules/admin/deposit-withdrawal/enums";
import { AdminCommissionDepositWithdrawalListResponseFactory } from "@/shared/repository/modules/admin/commission/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    SharedSelect,
    SharedInputCurrency,
    SharedEstimate,
    UIInput,
    UIButton,
    UITextArea
  },
  props: {
    history: {
      type: <PropType<AdminDepositWithdrawalListResponse[]>>Array,
      default: () => [],
    },
    depositWithdrawal: {
      type: <PropType<AdminDepositWithdrawalListResponse>>Object,
      required: true,
    },
    isWork: {
      type: Boolean,
      default: false,
    },
    isReject: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:history": null,
    "update:depositWithdrawal": null,
  },
  data() {
    return {
      isLoading: false,
      adminDepositWithdrawalShow: AdminDepositWithdrawalShowResponseFactory(),
      adminDepositWithdrawalRepository:
        this.$projectServices.adminDepositWithdrawalRepository,
      commissionPercent: <number>(0),
      depositWithdrawalRepository:
        this.$projectServices.depositWithdrawalRepository,
    };
  },
  async created() {
    try {
      this.isLoading = true;

      this.adminDepositWithdrawalShow =
        await this.adminDepositWithdrawalRepository.show(
          this.depositWithdrawal.id
        );

      if (this.isWork) {
        this.adminDepositWithdrawalShow.status =
          AdminDepositWithdrawalStatus.in_work;
      } else if (this.isReject) {
        this.adminDepositWithdrawalShow.status =
          AdminDepositWithdrawalStatus.reject;
      }

      const commissionRequest = {
        user_id: this.depositWithdrawal.user.id,
        currency_type: this.depositWithdrawal.currency_type,
        app_type: this.depositWithdrawal.app_type
      }

      const commission = await this.depositWithdrawalRepository.commission(commissionRequest)

      this.commissionPercent = commission.commission

    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedStatusOptions(): SharedSelectOption[] {
      return [
        SelectOptionFactory(
          {
            id: 0,
            label: "Создано",
            value: AdminDepositWithdrawalStatus.pending,
          },
          this.adminDepositWithdrawalShow.status
        ),
        SelectOptionFactory(
          {
            id: 1,
            label: "Ожидание подтверждения",
            value: AdminDepositWithdrawalStatus.waiting,
          },
          this.adminDepositWithdrawalShow.status
        ),
        SelectOptionFactory(
          {
            id: 2,
            label: "Отклонено",
            value: AdminDepositWithdrawalStatus.reject,
          },
          this.adminDepositWithdrawalShow.status
        ),
        SelectOptionFactory(
          {
            id: 3,
            label: "В работе",
            value: AdminDepositWithdrawalStatus.in_work,
          },
          this.adminDepositWithdrawalShow.status
        ),
        SelectOptionFactory(
          {
            id: 3,
            label: "Выполнено",
            value: AdminDepositWithdrawalStatus.completed,
          },
          this.adminDepositWithdrawalShow.status
        ),
      ];
    },

    isDisabled(): boolean {
      const { id, status, amount, currency_id } =
        this.adminDepositWithdrawalShow;

      return !id || !status || !amount || !currency_id || this.isLoading;
    },

    displaedUserAmount(): number {
      const commission = this.commissionPercent * this.adminDepositWithdrawalShow.amount * 0.01
      return this.adminDepositWithdrawalShow.amount - commission
    }
  },
  methods: {
    handleAmount(amount: number): void {
      this.adminDepositWithdrawalShow.amount = amount;

      const { transaction_volume, execution_price } =
        this.adminDepositWithdrawalShow;

      if (transaction_volume && execution_price) {
        this.adminDepositWithdrawalShow.transaction_volume =
          amount / execution_price;
        this.adminDepositWithdrawalShow.execution_price =
          amount / transaction_volume;
      }
    },

    handleTransactionVolume(transaction_volume: number): void {
      this.adminDepositWithdrawalShow.transaction_volume = transaction_volume;

      const { execution_price, amount } = this.adminDepositWithdrawalShow;

      this.adminDepositWithdrawalShow.amount =
        execution_price * transaction_volume;

      if (transaction_volume) {
        this.adminDepositWithdrawalShow.execution_price =
          amount / transaction_volume;
      }
    },

    handleExecutionPrice(execution_price: number): void {
      this.adminDepositWithdrawalShow.execution_price = execution_price;

      const { transaction_volume, amount } = this.adminDepositWithdrawalShow;

      this.adminDepositWithdrawalShow.amount =
        transaction_volume * execution_price;

      if (execution_price) {
        this.adminDepositWithdrawalShow.transaction_volume =
          amount / execution_price;
      }
    },

    handleClose(): void {
      this.$emit(
        "update:depositWithdrawal",
        AdminCommissionDepositWithdrawalListResponseFactory()
      );
    },

    async handleSave(): Promise<void> {
      try {
        this.isLoading = true;

        await this.adminDepositWithdrawalRepository.update(
          this.adminDepositWithdrawalShow.status,
          AdminDepositWithdrawalUpdateRequestFactory(
            this.adminDepositWithdrawalShow
          )
        );

        this.$emit(
          "update:history",
          await this.adminDepositWithdrawalRepository.list()
        );

        this.handleClose();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
