import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIButton } from "@/components/ui";

import { Language } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
  },
  data() {
    return {
      isLoading: false,
      message: "",
      authRepository: this.$projectServices.authRepository,
      userRepo: this.$projectServices.userRepo,
    }
  },
  computed: {
    displayedLang(): Language {
      return this.userRepo.userInfo.user_info.lang;
    },
  },
  methods: {
    async retryMail() {
      console.log("retryMail")
      this.isLoading = true
      this.message = ""
      try {
        await this.authRepository.retryNotification({lang: this.displayedLang || Language.ru })
      } catch (e) {
        const { message } = <Error>e;
        this.message = message;
      } finally {
        this.isLoading = false;
      }
    }
  }
});
