<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <button
      :class="
        b('bank', {
          active: bank.id === bankId,
          added: typeof bank.id === 'number',
        })
      "
      v-for="bank in banks"
      :key="bank.id"
      @click="handleBank(bank)"
    >
      {{ handleBankLabel(bank.correspondent_bank) }}

      <span
        v-if="typeof bank.id === 'number'"
        :class="b('icon')"
        v-html="displayedSvg.added"
      />

      <button :class="b('remove')" @click.stop="handleBankRemove(bank.id)">
        <span :class="b('icon')" v-html="displayedSvg.remove" />
      </button>
    </button>

    <button v-if="isCreateShow" :class="b('circle')" @click="handleBankCreate">
      <span :class="b('icon')" v-html="displayedSvg.circle" />
    </button>
  </div>
</template>
