import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

const AT = "@";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    view: {
      type: String,
      default: "",
    },
    placeholderView: {
      type: String,
      default: "",
    },
    maska: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    maxLength: {
      type: Number,
      default: 0,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isTelegram: {
      type: Boolean,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isNumber: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 2,
    }
  },
  emits: {
    "update:modelValue": null,
  },
  computed: {
    isFieldDisabled(): boolean {
      return this.isReadonly || this.isDisabled;
    },
  },
  methods: {
    changeModelValue(event: Event): void {
      const { value } = <HTMLInputElement>event.target;

      if (this.isTelegram && value) {
        this.$emit(
          "update:modelValue",
          value[0] === AT ? value : `${AT}${value}`
        );
      } else if (this.isNumber) {
        this.$emit("update:modelValue", Number(value));
      } else if (this.maxLength) {
        (<HTMLInputElement>event.target).value = value.substring(
          0,
          this.maxLength
        );

        this.$emit("update:modelValue", value.substring(0, this.maxLength));
      } else {
        this.$emit("update:modelValue", value);
      }
    },
  },
});
