import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('content'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label'))
      }, _toDisplayString(_ctx.$t("На вашу почту отправлена ссылка для подтверждения e-mail!")), 3),
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('button'))
      }, [
        _createVNode(_component_UIButton, {
          label: _ctx.$t('Отправить ссылку еще раз'),
          view: "main-small",
          disabled: _ctx.isLoading,
          onClick: _ctx.retryMail
        }, null, 8, ["label", "disabled", "onClick"])
      ], 2),
      (!_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.message), 1))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}