import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { AdminConfigCommissionField } from "../field";
import { SharedSelect } from "@/components/shared";

import { AdminCommissionExchangeType } from "@/shared/repository/modules/admin/commission/enums";
import { AdminCommissionFindOptions } from "@/data/admin/commission/find";
import { SharedSelectOption } from "@/components/shared/select/model";
import { SelectOptionFactory } from "@/shared/factory/select";
import { UIInput } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminConfigCommissionField,
    SharedSelect,
    UIInput
  },
  props: {
    type: {
      type: <PropType<AdminCommissionExchangeType>>String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  emits: {
    "update:type": null,
    "update:userId": null,
  },
  data() {
    return {
      usersRepo: this.$projectServices.usersRepo,
      userFilter: new String(""),
      sort: new String("")
    };
  },
  computed: {
    displayedOptions(): SharedSelectOption[] {
      return AdminCommissionFindOptions(this.type);
    },

    displayedUserIdOptions(): SharedSelectOption[] {
      let copy = this.usersRepo.users.slice()
      if (this.sort === "asc" || this.sort === "desc") {
        copy = copy
          .sort((a, b) => {
            if (a.email.toUpperCase() <  b.email.toUpperCase()) return this.sort == "asc" ? -1 : 1
            if (a.email.toUpperCase() >  b.email.toUpperCase()) return this.sort == "asc" ? 1 : -1
            return 0
          })
      }

      let sorted = copy
        .map((user) =>
          SelectOptionFactory(
            {
              id: user.index,
              label: `${user.id}: ${user.email}`,
              value: user.id,
            },
            this.userId
          )
        );

      if (this.userFilter && this.userFilter.trim()) {
        sorted = sorted
          .filter(user => {
            return user.label.indexOf(this.userFilter.trim()) > -1
          })
      }
      return sorted
    },

    isUserIdShow(): boolean {
      return this.type === AdminCommissionExchangeType.user;
    },
  },
  methods: {
    handleType(type: string): void {
      this.$emit("update:type", type);
    },

    handleUserId(userId: number): void {
      this.$emit("update:userId", userId);
    },

    changeSort() {
      if (this.sort !== "asc" && this.sort !== "desc") {
        this.sort = "asc"
        return
      }
      this.sort = this.sort === "asc" ? "desc" : "asc"
    }
  },
});
