<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <button :class="b()" @click="handleRow">
    <div :class="b('date-time')">
      <p :class="b('label', { dateTime: true })">
        {{ row.created_at }}
      </p>
    </div>

    <div :class="b('type')">
      <div :class="b('operation')">
        <p :class="b('label', { from: true })">
          {{ displayedAmountFrom }}
        </p>

        <p :class="b('label')">
          {{ row.currency_from.name.toUpperCase() }}
        </p>
      </div>

      <span
        :class="b('icon', { chevron: true })"
        v-html="displayedSvg.chevron"
      ></span>

      <div :class="b('operation')">
        <p :class="b('label', { to: true })">
          {{ displayedAmountTo }}
        </p>

        <p :class="b('label')">
          {{ row.currency_to.name.toUpperCase() }}
        </p>
      </div>
    </div>

    <div :class="b('commission')">
      <p :class="b('label')">
        {{ displayedExecutionPrice }}
      </p>
    </div>

    <div :class="b('volume')">
      <p :class="b('label')">
        {{ displayedTransactionVolume }}
      </p>
    </div>

    <div :class="b('commission')">
      <p :class="b('label')">
        {{ displayedCommission }}
      </p>
    </div>

    <div :class="b('id')">
      <p :class="b('label')">
        {{ row.id }}
      </p>
    </div>

    <div :class="b('status')">
      <div :class="b('group')">
        <span :class="b('icon')" v-html="displayedSvg[row.status]" />
      </div>
    </div>

    <div :class="b('commission')">
      <p :class="b('label')">
        {{ displayLastStatus(row.stage) }}
      </p>
    </div>

    <div :class="b('block')">
      <div :class="b('block-group')">
        <div :class="b('block-operations')">
          <p :class="b('label')">Операция</p>

          <div :class="b('block-type')">
            <div :class="b('block-operation')">
              <p :class="b('label', { from: true })">
                {{ displayedAmountFrom }}
              </p>

              <p :class="b('label')">
                {{ row.currency_from.name.toUpperCase() }}
              </p>
            </div>

            <span
              :class="b('icon', { chevron: true })"
              v-html="displayedSvg.chevron"
            ></span>

            <div :class="b('operation')">
              <p :class="b('label', { to: true })">
                {{ displayedAmountTo }}
              </p>

              <p :class="b('label')">
                {{ row.currency_to.name.toUpperCase() }}
              </p>
            </div>
          </div>
        </div>

        <div :class="b('block-date')">
          <p :class="b('label', { dateTime: true })">
            {{ row.created_at }}
          </p>

          <span :class="b('icon')" v-html="displayedSvg[row.status]" />
        </div>
      </div>

      <div :class="b('block-group')">
        <div :class="b('block-commission')">
          <p :class="b('label')">Цена</p>

          <p :class="b('label')">
            {{ displayedExecutionPrice }}
          </p>
        </div>

        <div :class="b('block-other')">
          <div :class="b('block-number')">
            <p :class="b('label')">{{ $t('№ сделки') }}</p>

            <p :class="b('label')">
              {{ row.id }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div :class="b('block-actions')">
      <button v-if="isRemoveShow" :class="b('action')" @click.stop="handleRemove">
        <span
          :class="b('icon', { remove: true })"
          v-html="displayedSvg.remove"
        ></span>
      </button>
    </div>
  </button>
</template>
