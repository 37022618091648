<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <SharedAdminHeader
      title="Заявки"
      :is-type="false"
      :is-loading="isLoading"
      :is-email="true"
      v-model:id="search.id"
      v-model:email="search.email"
      @search="handleSearch"
    />

    <div :class="b('wrapper')">
      <AdminApplicationHeader
        v-model:tab="tab"
        v-model:search="search.value"
        v-model:history="history"
        v-model:page="sort.page"
      />

      <AdminApplicationTable
        :class="b('table')"
        :search="search"
        :sort="sort"
        :tab="tab"
        v-model:history="history"
        v-model:deposit-withdrawal="depositWithdrawal"
        v-model:application="application"
        v-model:is-work="isWork"
        v-model:is-reject="isReject"
        @detailHistory="handleDetailHistory"
        @applicationHistory="handleApplicationHistory"
      />

      <SharedButtonIcon
        v-if="isMoreShow"
        :class="b('more')"
        view="gray-outline"
        @click="handleMore"
      >
        <template #icon-left>
          <span :class="b('more-icon')" v-html="displayedIcons.more"></span>
        </template>

        <template #label>
          {{ $t("Показать больше") }}
        </template>
      </SharedButtonIcon>
    </div>

    <transition name="opacity">
      <AdminApplicationEdit
        v-if="depositWithdrawal.id"
        :class="b('modal')"
        v-model:history="history"
        v-model:deposit-withdrawal="depositWithdrawal"
        v-model:is-work="isWork"
        v-model:is-reject="isReject"
      />
    </transition>

    <transition name="opacity">
      <AdminApplicationEditApplication
        v-if="application.id"
        :class="b('modal')"
        v-model:history="history"
        v-model:application="application"
        v-model:is-work="isWork"
        v-model:is-reject="isReject"
      />
    </transition>

    <transition name="opacity">
      <HistoryDetails
        :class="b('modal')"
        v-if="historyRow.id"
        :deposit-withdrawal="historyRow"
        @depositWithdrawal="closeHistoryRow"
      />
    </transition>

    <transition name="opacity">
      <HistoryApplicationDetails
        :class="b('modal')"
        v-if="applicationHistory.id"
        :application="applicationHistory"
        @application="closeHistoryApplicationHistory"
      />
    </transition>
  </div>
</template>
