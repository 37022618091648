import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedWindow } from "@/components/shared";

import { WalletRequisites } from "../requisites";
import { WalletFiatNew } from "../fiat-new";
import { WalletFiatPattern } from "../fiat-pattern";
import { WalletCryptoNew } from "../crypto-new";
import { WalletCryptoPattern } from "../crypto-pattern";

import { WalletModalFactory } from "@/shared/repository/modules/wallet/factory";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";
import { RequisitesWalletType } from "@/shared/repository/modules/requisites/enums";

type Component =
  | typeof WalletFiatNew
  | typeof WalletFiatPattern
  | typeof WalletCryptoNew
  | typeof WalletCryptoPattern;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    WalletRequisites,
    WalletFiatNew,
    WalletFiatPattern,
    WalletCryptoNew,
    WalletCryptoPattern,
  },
  data() {
    return {
      tab: AdminCurrencyType.fiat,
      requisite: RequisitesWalletType.pattern,
      walletRepo: this.$projectServices.walletRepo,
    };
  },
  emits: {
    "update": null
  },
  computed: {
    displayedComponent(): Component {
      switch (this.tab) {
        case AdminCurrencyType.fiat:
          switch (this.requisite) {
            case RequisitesWalletType.pattern:
              return WalletFiatPattern;
            case RequisitesWalletType.new:
              return WalletFiatNew;
            default:
              return WalletFiatPattern;
          }
        case AdminCurrencyType.crypto:
          switch (this.requisite) {
            case RequisitesWalletType.pattern:
              return WalletCryptoPattern;
            case RequisitesWalletType.new:
              return WalletCryptoNew;
            default:
              return WalletCryptoPattern;
          }
        default:
          switch (this.requisite) {
            case RequisitesWalletType.pattern:
              return WalletFiatPattern;
            case RequisitesWalletType.new:
              return WalletFiatNew;
            default:
              return WalletFiatPattern;
          }
      }
    },

    isBig(): boolean {
      return (
        this.tab === AdminCurrencyType.fiat &&
        this.requisite === RequisitesWalletType.new
      );
    },
  },
  methods: {
    handleTab(tab: AdminCurrencyType): void {
      this.tab = tab;
    },

    handleClose(): void {
      this.walletRepo.UPDATE_MODAL(WalletModalFactory());
    },

    update() {
      this.$emit("update")
    }
  },
});
