import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WalletRequisites = _resolveComponent("WalletRequisites")!
  const _component_SharedWindow = _resolveComponent("SharedWindow")!

  return (_openBlock(), _createBlock(_component_SharedWindow, {
    title: "Ввод",
    "is-big": _ctx.isBig,
    "is-center": true,
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('wrapper'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('header'))
        }, [
          _createVNode(_component_WalletRequisites, {
            requisite: _ctx.requisite,
            "onUpdate:requisite": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.requisite) = $event))
          }, null, 8, ["requisite"])
        ], 2),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedComponent), { onUpdate: _ctx.update }, null, 40, ["onUpdate"]))
      ], 2)
    ]),
    _: 1
  }, 8, ["is-big", "onClose"]))
}