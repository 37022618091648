import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_UITextArea = _resolveComponent("UITextArea")!
  const _component_SharedInputCurrency = _resolveComponent("SharedInputCurrency")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedWindow = _resolveComponent("SharedWindow")!

  return (_openBlock(), _createBlock(_component_SharedWindow, {
    class: _normalizeClass(_ctx.b()),
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('wrapper'))
      }, [
        _createElementVNode("h3", {
          class: _normalizeClass(_ctx.b('title'))
        }, "Редактирование заявки", 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('fields'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('field-title'))
            }, "Статус", 2),
            _createVNode(_component_SharedSelect, {
              view: "light",
              options: _ctx.displayedStatusOptions,
              value: _ctx.adminApplicationShow.status,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.adminApplicationShow.status) = $event))
            }, null, 8, ["options", "value"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('field-title'))
            }, "Этап (Добавляте этапы построчно используя Enter)", 2),
            _createVNode(_component_UITextArea, {
              view: "light",
              rows: "4",
              placeholder: "Добавьте комментарий при необходимости",
              modelValue: _ctx.adminApplicationShow.stage,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.adminApplicationShow.stage) = $event))
            }, null, 8, ["modelValue"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('field-title'))
            }, "Изменить сумму сделки", 2),
            _createVNode(_component_SharedInputCurrency, {
              placeholder: "Введите новую сумму",
              value: _ctx.adminApplicationShow.amount,
              currency: _ctx.adminApplicationShow.currency_from,
              "onUpdate:value": _ctx.handleAmount
            }, null, 8, ["value", "currency", "onUpdate:value"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('field-title'))
            }, "Изменить объем сделки", 2),
            _createVNode(_component_SharedInputCurrency, {
              placeholder: "Введите новую сумму",
              value: _ctx.adminApplicationShow.transaction_volume,
              currency: _ctx.adminApplicationShow.currency_from,
              "onUpdate:value": _ctx.handleTransactionVolume
            }, null, 8, ["value", "currency", "onUpdate:value"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('field-title'))
            }, "Изменить цену исполнения", 2),
            _createVNode(_component_SharedInputCurrency, {
              placeholder: "Введите новую сумму",
              value: _ctx.adminApplicationShow.execution_price,
              currency: _ctx.adminApplicationShow.currency_from,
              "onUpdate:value": _ctx.handleExecutionPrice
            }, null, 8, ["value", "currency", "onUpdate:value"])
          ], 2)
        ], 2),
        _createVNode(_component_UIButton, {
          label: "Сохранить",
          view: "main",
          disabled: _ctx.isDisabled,
          onClick: _ctx.handleSave
        }, null, 8, ["disabled", "onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["class", "onClose"]))
}