<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('wrapper')" v-click-outside="handleHide">
      <span :class="b('icon')" v-html="displayedIcon"></span>

      <button :class="b('close')" @click="handleClose">
        <span :class="b('close-icon')" v-html="displayedCloseIcon"></span>
      </button>

      <div :class="b('info')">
        <h3 :class="b('title')">{{ $t(displayedModal.title) }}</h3>

        <p
          :class="b('label')"
          v-if="displayedModal.label"
          v-html="$t(displayedModal.label)"
        ></p>
      </div>

      <UIButton
        :class="b('ok')"
        :label="displayedModal.actionLabel"
        view="main"
        @click="handleOk"
      />
    </div>
  </div>
</template>
