<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow :class="b()" title="Детали операции" @close="handleClose">
    <template #default>
      <div :class="b('wrapper')">
        <div :class="b('list')">
          <div :class="b('item')">
            <h4 :class="b('item-title')">
              {{ $t("Дата и время") }}
            </h4>

            <p :class="b('item-label')">
              {{ application.created_at }}
            </p>
          </div>

          <div :class="b('item')">
            <h4 :class="b('item-title')">№ {{ $t("сделки") }}</h4>

            <p :class="b('item-label')">
              {{ application.id }}
            </p>
          </div>

          <div :class="b('item-operation')">
            <h4 :class="b('item-title')">
              {{ $t("Операция") }}
            </h4>

            <div :class="b('item-group')">
              <span
                :class="b('item-icon')"
                v-html="displayedChevronIcon"
              ></span>

              <div :class="b('item-info')">
                <p :class="b('item-label')">
                  {{ displayedAmountFrom }}
                  {{ application.currency_from.name.toUpperCase() }}
                </p>

                <p :class="b('item-label')">
                  {{ displayedAmountTo }}
                  {{ application.currency_to.name.toUpperCase() }}
                </p>
              </div>
            </div>
          </div>

          <div :class="b('item')">
            <h4 :class="b('item-title')">
              {{ $t("Цена исполнения") }}
            </h4>

            <p :class="b('item-label')">
              {{ displayedExecutionPrice }}
            </p>
          </div>

          <div :class="b('item')">
            <h4 :class="b('item-title')">
              {{ $t("Комиссия") }}
            </h4>

            <p :class="b('item-label')">
              {{ displayedCommissions }}
            </p>
          </div>

          <div :class="b('item')">
            <h4 :class="b('item-title')">
              {{ $t("Статус") }}
            </h4>

            <p :class="b('item-label')">
              {{ $t(displayedStatus) }}
            </p>
          </div>

          <div :class="b('item-stage')">
            <h4 :class="b('item-title')">
              {{ $t("Этап") }}
            </h4>

            <div :class="b('item-steps')">
              <p :class="b('item-step')">{{ application.stage }}</p>
            </div>
          </div>
        </div>

        <UIButton label="Закрыть" view="main" @click="handleClose" />
      </div>
    </template>
  </SharedWindow>
</template>
