<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <slot />

    <p
      v-if="displayedLabel"
      :class="b('label', { center: isCenter, absolute: isAbsolute })"
    >
      {{ $t(displayedLabel) }}
    </p>
  </div>
</template>
